<template>
  <div class="container-fluid d-flex flex-column p-0">

    <weseedo-header></weseedo-header>

    <div class="container flex-grow-1 py-4">
      <div class="row justify-content-center">
        <div class="d-flex flex-column col-12 col-sm-10 text-center">
          <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('MeetingParticipantAlreadyActive.Meeting already active') }}</h2>
          <p class="mb-3">{{ $t('MeetingParticipantAlreadyActive.The link that you used to access this meeting is already in use') }}</p>
          <p class="mb-1">{{ $t('MeetingParticipantAlreadyActive.Please verify that you are not already present in this meeting in another browser tab') }}</p>

          <div class="d-flex align-self-center align-items-center justify-content-center rounded-circle border border-primary w-px-100 h-px-100 mt-4">
            <font-awesome-icon :icon="['fal', 'link']" class="font-size-px-40" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetingParticipantAlreadyActive',
  data () {
    return {
    }
  },
  mounted () {
    this.$webrtc.stopLocalStreams()
  }
}
</script>

<style lang="scss" scoped>

</style>
