<template>
    <div class="row justify-content-center ml-1 mr-1 mb-1" v-show="(['assistent', 'assistent_video'].includes(userRole))">
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
            <select class="form-control" id="search_site" name="search_site" ref="search_site" v-model="localSearchSite" v-select2  @change="handleSearchSiteChange">
                <option value="" selected>{{ $t('WaitingRoom.Show all sites') }}</option>
                <option :value="site.id" v-for="site in $store.getters.getUserSites" :key="site.id">{{ site.name }}</option>
            </select>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2" v-show="['assistent', 'assistent_video'].includes(userRole)">
            <select class="form-control" id="search_agent" name="search_agent" ref="search_agent" v-model="localSearchAgent" v-select2 @change="handleSearchAgentChange">
                <option value="">{{ $t('WaitingRoom.Show all agents') }}</option>
                <option :value="user.id" v-for="user in siteAgentList" :key="user.user">{{ user.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SiteAgentSelector',
  props: ['siteAgentList', 'searchSite', 'searchAgent', 'userRole'],
  data () {
    return {
      localSearchAgent: this.searchAgent
    }
  },
  computed: {
    localSearchSite: {
      get () {
        // set the searchSite value to the first site if the user has only one site
        if (this.$store.getters.getUserSites.length === 1) {
          this.$emit('update:searchSite', this.$store.getters.getUserSites[0].id)
          this.$emit('getSiteUsers')
          return this.$store.getters.getUserSites[0].id
        }
        return this.searchSite
      },
      set (value) {
        if (typeof value === 'string') {
          this.$emit('update:searchSite', value)
        }
      }
    }
  },
  methods: {
    handleSearchAgentChange () {
      // Emit an event with the updated value
      this.$emit('update:searchAgent', this.localSearchAgent)
      this.$emit('getMeetings', 1)
    },
    handleSearchSiteChange () {
      this.$emit('getSiteUsers')
    }
  },
  watch: {
    searchSite: {
      handler (newVal) {
        const userSites = this.$store.getters.getUserSites

        if (userSites.length === 1 && !newVal) {
          this.localSearchSite = ''
          return
        }

        this.localSearchSite = newVal
        this.$emit('update:searchSite', newVal)

        this.$nextTick(() => {
          if (!newVal) {
            this.$(this.$refs.search_site).val(newVal?.target?.value).trigger('change.select2')
          }
        })
      }
    },
    searchAgent (newVal) {
      this.localSearchAgent = newVal
      this.$emit('update:searchAgent', newVal)
      this.$nextTick(() => {
        if (newVal?.length === 0) {
          this.$(this.$refs.search_agent).val(newVal?.target?.value).trigger('change.select2')
        }
      })
    }
  }
}
</script>
