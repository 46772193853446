<template>
  <div class="container-fluid d-flex flex-column p-0">

    <weseedo-header></weseedo-header>

    <div class="container flex-grow-1 py-4">
      <div class="row justify-content-center">
        <div class="d-flex flex-column col-12 col-sm-9 col-md-7 col-lg-5 text-center">
          <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('Logout.Signed out') }}</h2>
          <p>{{ $t('Logout.You are signed out') }}</p>
        </div>
      </div>
      <div class="row justify-content-center px-4">
        <div class="col-12 col-sm-9 col-md-7 col-lg-5 text-center mb-5 mt-5">
          <router-link tag="button" :to="{'name': 'login'}" class="btn btn-lg btn-primary mt-4">{{ $t('Logout.Login') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  data () {
    return {
    }
  },
  mounted () {
    this.$store.commit('setNotificationBar', {})

    this.$webrtc.resetIsInitialConnection()
    this.$webrtc.stopLocalStreams()
    this.$axios.post(this.$axios.routes.set_logout, {})
    this.$axios.logout(false)
    this.$webrtc.disconnect(false)
    this.$noSleep.disable()
    // this.$router.push({ name: 'login' })
  }
}
</script>

<style lang="scss" scoped>

</style>
