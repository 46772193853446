import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import Login from './components/Login'
import AutoLogin from './components/AutoLogin'
import Logout from './components/Logout'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
import ActivateAccount from './components/ActivateAccount'
import Appointment from './components/Appointment'
import Meeting from './components/Meeting'
import MeetingCancel from './components/MeetingCancel'
import ForcedLogout from './components/ForcedLogout'
import WaitingRoom from './components/WaitingRoom'
import MeetingParticipantAlreadyActive from './components/MeetingParticipantAlreadyActive'
import UnsupportedBrowser from './components/UnsupportedBrowser'
import WebsiteButton from './components/iframe/WebsiteButton'
import WebsiteForm from './components/iframe/WebsiteForm'
import WebsiteFormOverview from './components/iframe/WebsiteFormOverview'
import HardwareTest from './components/HardwareTest'
import GenericError from './components/GenericError'
import ErrorNotFound from './ErrorNotFound.vue'

const routes = [
  { path: '/', name: 'home', component: Login },
  { path: '/login', name: 'login', component: Login },
  { path: '/login/:token', name: 'auto_login', component: AutoLogin },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '/forgot', name: 'forgot_password', component: ForgotPassword },
  { path: '/reset/:token?', name: 'reset_password', component: ResetPassword },
  { path: '/activate/:token?', name: 'activate_account', component: ActivateAccount },
  { path: '/appointment', name: 'appointment', component: Appointment },
  { path: '/meeting/:token?', name: 'meeting', component: Meeting },
  { path: '/meeting/:token/cancel', name: 'meeting_cancel', component: MeetingCancel },
  { path: '/forced_logout', name: 'forced_logout', component: ForcedLogout },
  { path: '/waiting_room', name: 'waiting_room', component: WaitingRoom },
  { path: '/visitor_already_active', name: 'meeting_participant_already_active', component: MeetingParticipantAlreadyActive, props: true },
  { path: '/unsupported_browser', name: 'unsupported_browser', component: UnsupportedBrowser, props: true },
  { path: '/website_button', name: 'website_button', component: WebsiteButton },
  { path: '/plannen', name: 'form_plannen_overview', component: WebsiteFormOverview },
  { path: '/plannen/:site', name: 'form_plannen', component: WebsiteForm },
  { path: '/schedule', name: 'form_schedule_overview', component: WebsiteFormOverview },
  { path: '/schedule/:site', name: 'form_schedule', component: WebsiteForm },
  { path: '/hardwaretest', name: 'hardware_test', component: HardwareTest },
  { path: '/error', name: 'error', component: GenericError },
  { path: '*', name: 'ErrorNotFound', component: ErrorNotFound }
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

// Only add unauthenticated routes when access is not allowed when a user is signed in
router.unauthenticatedRoutes = [
  'home',
  'login',
  'auto_login',
  'error'
]

// Do NOT add 'meeting' to authenticated arrays, authentication checks are done in meeting component with mounted function
router.authenticatedRoutes = [
  'logout',
  'forced_logout',
  'appointment',
  'waiting_room'
]

// Do not add 'meeting' to authenticated arrays, authentication checks are done in meeting component with mounted function
router.authenticatedParticipantRoutes = [
  ''
]

// If the user browser does not support WebRTC, only allow these routes
router.weseedoUnsupportedAllowedRoutes = [
  'unsupported_browser',
  'forgot_password',
  'reset_password',
  'website_button',
  'form_plannen',
  'form_schedule'
]

// If UI is disabled, disallow these routes
router.UIDisabledDisallowedRoutes = [
  'appointment'
]

// Only show notification bar on these pages. It will always show on authenticated pages or when store.meetingDisconnectedAs or store.meetingEndedAs is agent.
router.enableNotificationBarRoutes = [
  'home',
  'login',
  'auto_login',
  'forgot_password',
  'reset_password'
]

// Disable reconnecting message on following pages. Reconnecting message will NOT be shown on this pages
router.disableReconnectingMessageRoutes = [
  'home',
  'login',
  'auto_login',
  'forgot_password',
  'reset_password',
  'unsupported_browser'
]

const nextRoute = function (name, to, from, next) {
  if (name === null && JSON.stringify(from.query) === JSON.stringify(to.query)) {
    next()
  } else {
    if (from.name === 'login' && from.query.state) {
      delete from.query.state
    }
    let mergedQuery = Object.assign(from.query, to.query)
    if (to.name !== 'login') {
      delete mergedQuery.session_expired
    }
    if (to.name !== 'auto_login' && to.name !== 'login') {
      delete mergedQuery.redirect_after_login
    }

    next({
      name: name === null ? to.name : name,
      params: to.params,
      query: mergedQuery
    })
  }
}

router.beforeEach((to, from, next) => {
  console.log(to.name, from.name)
  if (to.name === from.name) {
    next(false)
    return false
  }

  window.DetectRTC.load(async () => {
    const isChrome = window.DetectRTC.browser.isChrome
    const isSafari = window.DetectRTC.browser.isSafari
    const isFirefox = window.DetectRTC.browser.isFirefox
    const isOpera = window.DetectRTC.browser.isOpera

    // const isMobileDevice = window.DetectRTC.isMobileDevice
    // const isIOS = window.DetectRTC.osName === 'iOS'
    // const isScreenSharing = window.DetectRTC.isScreenCapturingSupported
    // const isIpad = navigator.userAgent.includes('Mac') && typeof navigator.maxTouchPoints !== 'undefined' && navigator.maxTouchPoints > 2

    const isWebRtcSupported = window.DetectRTC.isWebRTCSupported

    let isWeSeeDoSupported = true

    if (!isWebRtcSupported) {
      isWeSeeDoSupported = false
    }
    // if (isChrome && isIOS) {
    //   isWeSeeDoSupported = false
    // }
    if (!isChrome && !isFirefox && !isSafari && !isOpera) {
      isWeSeeDoSupported = false
    }

    console.log('Go to route: ' + to.name + ' (' + to.path + ')')

    if (to.name !== 'meeting' && store.getters.getMeetingEnded === true) {
      store.commit('setMeetingEnded', false)
    }
    if (to.name !== 'forced_logout' && store.getters.getForcedLogout === true) {
      store.commit('setForcedLogout', false)
    }

    window.isWeSeeDoSupported = isWeSeeDoSupported

    if (!router.weseedoUnsupportedAllowedRoutes.includes(to.name) && !isWeSeeDoSupported && typeof to.params.token === 'undefined') {
      nextRoute('unsupported_browser', to, from, next)
    } else if (to.name === 'unsupported_browser' && isWeSeeDoSupported) {
      nextRoute('login', to, from, next)
    } else {
      if (router.authenticatedRoutes.includes(to.name)) {
        if (store.getters.getAccessToken !== null && store.getters.getUser !== false) {
          if (store.getters.getProduct === 'personal' && !store.getters.userHasFeature('appointment') && ['waiting_room', 'appointment'].includes(to.name)) {
            nextRoute('meeting', to, from, next)
          } else if (store.getters.getProduct === 'direct' && to.name === 'waiting_room') {
            nextRoute('appointment', to, from, next)
          } else {
            nextRoute(null, to, from, next)
          }
        } else {
          nextRoute('login', to, from, next)
        }
      } else if (router.authenticatedParticipantRoutes.includes(to.name)) {
        if (store.getters.getMeeting !== false) {
          nextRoute(null, to, from, next)
        } else {
          nextRoute('login', to, from, next)
        }
      } else if (router.unauthenticatedRoutes.includes(to.name)) {
        if (store.getters.getAccessToken === null && store.getters.getUser === false) {
          nextRoute(null, to, from, next)
        } else {
          if (store.getters.getProduct === 'personal') {
            nextRoute('meeting', to, from, next)
          } else {
            nextRoute('appointment', to, from, next)
          }
        }
      } else {
        nextRoute(null, to, from, next)
      }
    }
  })
})

export default router
