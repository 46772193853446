export const extractQueryParams = uri => {
  uri = uri ? uri.toString() : ''
  const search = uri.includes('?') ? uri.split(/\?(.+)/)[1] : ''
  let result = {}
  result = search ? search.split('&').reduce((prev, curr) => {
    const arr = curr.split('=')
    prev[arr[0]] = arr[1]
    return prev
  }, result) : result
  return result
}
