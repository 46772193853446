<template>
  <div ref="waitingRoomContainer" class="container-fluid d-flex flex-column p-0">
    <div class="d-flex flex-column w-100 h-100 overflow-auto">

      <weseedo-header></weseedo-header>

      <!-- loading meetings -->
      <div class="container flex-grow-1 py-3 mb-3" v-if="meetings  === null">

        <div class="row justify-content-center ml-1 mr-1 w-100">
          <div class="col-12 pl-0 pr-0">
            <h2 class="font-weight-normal text-center border-bottom pb-2 mb-3">{{ $t('WaitingRoom.Scheduled appointments') }}</h2>
          </div>
          <div class="w-100">
            <appointment-skeleton :numberOfSkeletons="2" :showLoader="meetings === null"></appointment-skeleton>
          </div>
        </div>
      </div>

      <!-- meeting list -->
      <div class="container flex-grow-1 py-3 mb-3" v-show="meetingList !== null && meetingList.length > 0">
        <div class="row justify-content-center ml-1 mr-1">
          <div class="col-12 pl-0 pr-0">
            <h2 class="font-weight-normal text-center border-bottom pb-2 mb-3">{{ $t('WaitingRoom.Scheduled appointments') }}</h2>
          </div>
        </div>

        <!-- filters -->
        <SiteAgentSelector
          :siteAgentList="siteAgentList"
          :searchSite="searchSite"
          :searchAgent="searchAgent"
          :userRole="$store.getters.getUser.role"
          @getSiteUsers="getSiteUsers"
          @getMeetings="getMeetings(1)"
          @update:searchSite="searchSite = $event"
          @update:searchAgent="searchAgent = $event"
        />

        <!-- next appointment header -->
        <div class="row ml-1 mr-1">
          <div class="col-12 text-center bg-blue pt-2 pb-2 mb-2 rounded-top font-size-rem-11 text-uppercase">
            {{ $t('WaitingRoom.Next appointment') }}
          </div>
        </div>

        <!-- for loop meetings -->
          <transition name="fade">
            <div id="scrollableDiv" v-if="meetingList.length > 0">
              <template v-for="(meeting, index) in meetingList">

                <!-- next appointments header -->
                <div class="row ml-1 mr-1 mt-4" :key="meeting.id" v-if="index === 1">
                  <div class="col-12 text-center bg-blue mb-2 pt-2 pb-2 rounded-top font-size-rem-11 text-uppercase">
                    {{ $t('WaitingRoom.Other appointments ({X} appointments)', { 'X': meetings.length -1, 'S': meetings.length - 1 === 1 ? $t('WaitingRoom.Appointment') : $t('WaitingRoom.Appointments')}) }}
                  </div>
                </div>

                <!-- left or top part -->
                <div class="row ml-1 mr-1 mb-2 border-top border-left border-right border-bottom" :key="meeting.id + '_results_'+index">
                  <div class="col-12 col-sm-5 col-md-4 col-lg-3 text-center pt-2 pb-2 pt-sm-4 pb-sm-4">
                    <div class="d-inline d-sm-block font-size-rem-12" v-if="moment(meeting.room_available_from).utcOffset(meeting.timezone_offset).format($t('Formats.Date')) !== moment().format($t('Formats.Date')) && moment(meeting.room_available_from).utcOffset(meeting.timezone_offset).format($t('Formats.Date')) === moment(meeting.room_available_till).utcOffset(meeting.timezone_offset).format($t('Formats.Date'))">
                      {{ moment(meeting.room_available_from).utcOffset(meeting.timezone_offset).format($t('Formats.Date')) }}
                    </div>
                    <div class="d-inline d-sm-block font-size-rem-9">
                      {{ $t('WaitingRoom.From') }}
                    </div>
                    <div class="d-inline d-sm-block font-size-rem-12" v-if="moment(meeting.room_available_from).utcOffset(meeting.timezone_offset).format($t('Formats.Date')) !== moment(meeting.room_available_till).utcOffset(meeting.timezone_offset).format($t('Formats.Date'))">
                      {{  moment.utc(meeting.room_available_from).local().format($t('Formats.Date')) }}
                    </div>
                    <div class="d-inline d-sm-block font-size-rem-12" :class="{ 'clearfix': moment(meeting.room_available_from).format($t('Formats.Date')) !== moment(meeting.room_available_till).format($t('Formats.Date')) }">
                      {{  moment.utc(meeting.room_available_from).local().format($t('Formats.Time')) }}
                    </div>
                    <div class="d-inline d-sm-block font-size-rem-9">
                      {{ $t('WaitingRoom.Until') }}
                    </div>
                    <div class="d-inline d-sm-block font-size-rem-12" v-if="moment(meeting.room_available_from).utcOffset(meeting.timezone_offset).format($t('Formats.Date')) !== moment(meeting.room_available_till).utcOffset(meeting.timezone_offset).format($t('Formats.Date'))">
                      {{  moment.utc(meeting.room_available_till).local().format($t('Formats.Date')) }}
                    </div>
                    <div class="d-inline d-sm-block font-size-rem-12">
                      {{  moment.utc(meeting.room_available_till).local().format($t('Formats.Time')) }}
                    </div>
                    <div class="mt-2 border-top d-none d-sm-block"></div>
                    <div class="d-block pt-2">
                      <a href="#" v-if="typeof meeting.chat_start === 'undefined' || meeting.chat_start === 0" @click.prevent="editAppointment(meeting)" class="d-inline d-sm-block text-darkblue font-size-rem-9">{{ $t('WaitingRoom.Edit appointment') }}</a>
                      <a href="#" @click.prevent="cancelDialog(meeting)" class="d-inline d-sm-block ml-3 ml-sm-0 text-darkblue font-size-rem-9">
                        <template v-if="typeof meeting.chat_start === 'undefined' || meeting.chat_start === 0">{{ $t('WaitingRoom.Cancel appointment') }}</template>
                        <template v-else>{{ $t('WaitingRoom.Delete appointment') }}</template>
                      </a>
                    </div>
                  </div>

                  <!-- right or bottom part -->
                  <div class="d-flex flex-column flex-lg-row col-12 col-sm-7 col-md-8 col-lg-9 bg-white pt-4 pb-4 pl-5 font-size-rem-11">

                    <!-- information -->
                    <div class="d-flex flex-column">
                      <!-- participants -->
                      <div class="d-flex margin-min-left-25 align-items-center font-weight-bold" v-for="(participant, index) in getParticipants(meeting.participants)" :key="participant.id">
                        <span class="dot-online w-px-15 h-px-15 margin-right-10" v-if="participant.status === 'online' && (index > 0 || !participant.user) && participant.deleted_at === null"></span>
                        <span class="dot-offline w-px-15 h-px-15 margin-right-10" v-if="participant.status !== 'online' && (index > 0 || !participant.user) && participant.deleted_at === null"></span>
                        <span class="h-px-25" :class="[{'border-bottom-dashed': ((participant.email && participant.email !== '') || (participant.phone && participant.phone !== '')) && (index > 0 || !participant.user) && participant.deleted_at === null}, {'text-decoration-line-through': participant.deleted_at !== null }, {'text-gray': participant.deleted_at !== null }, { 'margin-left-25': (index === 0 && participant.user) || participant.deleted_at !== null }]" v-tooltip:bottom="participantToolTip(participant, index)">{{ participant.name }}</span>
                        <span class="ml-2 px-1 pt-1 font-size-px-15 cursor-pointer" @click="copyMeetingUrlParticipant($event, participant.url)" v-if="participant.deleted_at === null && (participant.showLink || (!meeting.send_sms && !meeting.send_email))"><font-awesome-icon :icon="['fal', 'link']" /></span>
                        <span class="ml-2 px-1 pt-1 font-size-px-15" v-if="participant.deleted_at !== null && participant.deleted_reason" v-tooltip:bottom="participant.deleted_reason"><font-awesome-icon :icon="['fal', 'info-circle']" /></span>
                      </div>

                      <!-- general info -->
                      <div class="mt-3">{{ $t('WaitingRoom.Site') }}: {{ meeting.site.name }} </div>
                      <div>{{ $t('WaitingRoom.Created at') }} : {{ moment(meeting.room_created).format($t('Formats.DateTime')) }}</div>
                      <div v-if="meeting.created_by.id && (meeting.created_by.id !== $store.getters.getUser.id || meeting.participants[0].user !== $store.getters.getUser.id)">{{ $t('WaitingRoom.Created by') }}: {{ meeting.created_by.name }}</div>
                      <div v-if="meeting.created_by_site.id">{{ $t('WaitingRoom.Created via') }}: {{ meeting.created_by_site.name }}</div>
                      <div v-if="meeting.modified_at">{{ $t('WaitingRoom.Modified at') }}: {{ moment(meeting.modified_at).format($t('Formats.DateTime')) }}</div>
                      <div v-if="meeting.modified_by.id !== null && meeting.modified_by.id !== $store.getters.getUser.id">{{ $t('WaitingRoom.Modified by') }}: {{ meeting.modified_by.name }}</div>
                      <div v-if="meeting.recurrence"><em>{{ $t('WaitingRoom.This meeting is part of a recurring series') }}</em></div>
                      <div class="mt-3 "  v-if="meeting.custom_fields && meeting.custom_fields.length">
                        <div v-for="(field, index) in meeting.custom_fields" :key="index">
                          {{ field.fieldName }}:  {{ field.fieldValue }}
                        </div>
                      </div>
                    </div>

                    <!-- start button and chat button -->
                    <div class="d-flex ml-lg-auto mr-lg-3 mt-3 mt-lg-0 align-items-center align-self-baseline align-self-lg-center" v-if="$root.isAppReconnecting === false">
                      <!-- start button -->
                      <button class="btn btn-block btn-outline-primary border-dashed" v-if="meeting.participants[0].user && $store.getters.userHasFeature('waitingroom') && $store.getters.getUser.id === meeting.participants[0].user && $store.getters.getMeeting === false && refreshMeetingsDelay === true" disabled>
                        {{ $t('WaitingRoom.Wait for visitor') }}
                      </button>
                      <button class="btn btn-block btn-outline-primary border-dashed" v-else-if="meeting.participants[0].user && meeting.is_currently_active && refreshMeetingsDelay === false" disabled>
                        {{ $t('WaitingRoom.Meeting active') }}
                      </button>
                      <button class="btn btn-block btn-primary" @click="joinMeeting(meeting)" v-else-if="$store.getters.userHasFeature('waitingroom') && ((!meeting.participants[0].user && $store.getters.getUser.role === 'assistent_video' && meeting.site.meeting_takeover_enabled === true) || (meeting.participants[0].user && $store.getters.getUser.id === meeting.participants[0].user)) && showJoinMeetingButton(meeting.participants) === true && $store.getters.getMeetingStarted === false && joinMeetingLoading !== meeting.room_id && refreshMeetingsDelay === false" :disabled="joinMeetingLoading !== false">
                        {{ $t('WaitingRoom.Start meeting') }}
                      </button>
                      <button class="btn btn-block btn-primary " @click="confirmTakeOverMeeting(meeting)" v-else-if="$store.getters.userHasFeature('waitingroom') && (typeof meeting.chat_duration === 'undefined' || meeting.chat_duration === null || meeting.chat_duration < 1) && meeting.site.meeting_takeover_enabled === true && $store.getters.getUser.role === 'assistent_video' && showJoinMeetingButton(meeting.participants) === true && $store.getters.getMeetingStarted === false && joinMeetingLoading !== meeting.room_id && refreshMeetingsDelay === false" :disabled="joinMeetingLoading !== false">
                        {{ $t('WaitingRoom.Takeover meeting') }}
                      </button>
                      <button class="btn btn-block btn-primary" disabled="disabled" v-else-if="meeting.participants[0].user && $store.getters.userHasFeature('waitingroom') && showJoinMeetingButton(meeting.participants) === true && $store.getters.getMeetingStarted === false && joinMeetingLoading === meeting.room_id && refreshMeetingsDelay === false">
                        <font-awesome-icon :icon="['fal', 'spinner']" class="text-white" spin role="presentation" /> {{ $t('WaitingRoom.Starting') }}
                      </button>
                      <button class="btn btn-block btn-outline-primary border-dashed" v-else-if="meeting.participants[0].user && $store.getters.userHasFeature('waitingroom') && $store.getters.getUser.id === meeting.participants[0].user && showJoinMeetingButton(meeting.participants) === false && $store.getters.getMeeting === false && refreshMeetingsDelay === false" disabled>
                        {{ $t('WaitingRoom.Wait for visitor') }}
                      </button>
                      <!-- send message to waiting room from visitor -->
                      <div class="position-absolute position-top-3 position-right-3 cursor-pointer" @click.prevent="waitingRoomMessageDialog(meeting)" v-tooltip:bottom="!$root.isIpad && !$root.isMobileDevice ? $t('WaitingRoom.Post notice in the waiting room') : ''" v-if="$store.getters.userHasFeature('waitingroom_notification')">
                        <font-awesome-icon :icon="['fal', 'comment']" class="font-size-px-27" v-if="!meeting.waiting_room_text || meeting.waiting_room_text === ''" />
                        <font-awesome-icon :icon="['fal', 'comment-lines']" class="font-size-px-27" v-else />
                      </div>
                      <!-- comment added in meeting -->
                      <div class="position-absolute position-top-3 position-right-11 cursor-pointer" @click.prevent="commentDialog(meeting)" v-tooltip:bottom="!$root.isIpad && !$root.isMobileDevice ? $t('WaitingRoom.Show added comment') : ''" v-if="meeting.comment">
                        <font-awesome-icon :icon="['fal', 'info-circle']" class="font-size-px-27" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="d-flex justify-content-center ">
                <div id="loadMore" class="w-100"  v-if="pagination.hasNextPage" ref="loadMore" >
                  <appointment-skeleton :numberOfSkeletons="2" :showLoader="isFetching"></appointment-skeleton>
                  <p v-if="!isFetching" @click="loadMore">{{  $t('WaitingRoom.Load More') }} ...</p>
                </div>
              </div>
              </div>
          </transition>
        </div>

      <!-- no meetings found -->
      <div class="containers flex-grow-1 py-3 " v-if="meetings !== null && meetingList.length === 0">
          <div class="row justify-content-center">
            <div class="d-flex flex-column col-12 col-sm-10 col-md-8 col-lg-6 text-center">
              <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('WaitingRoom.Scheduled appointments') }}</h2>
              <appointment-skeleton :numberOfSkeletons="2" :showLoader="isFetching"></appointment-skeleton>
              <div v-if="!isFetching" class="d-flex flex-column text-center">
                <p class="mb-1">{{ $t('WaitingRoom.No meetings are scheduled') }}</p>

                <div class="d-flex align-self-center align-items-center justify-content-center rounded-circle border border-primary w-px-100 h-px-100 mt-4">
                  <font-awesome-icon :icon="['fal', 'calendar-xmark']" class="font-size-px-40" />
                </div>
              </div>

            </div>
          </div>
          <div class="row justify-content-center px-4" v-if="!isFetching && $store.getters.isUINavigationEnabled && (typeof $route.query.disable_menuitem_appointment === 'undefined' || $route.query.disable_menuitem_appointment === 'false')">
            <div class=" text-center mb-3 mt-4">
              <router-link tag="button" :to="{'name': 'appointment'}" class="btn btn-lg btn-primary mt-3">{{ $t('WaitingRoom.Schedule appointment') }}</router-link>
            </div>
            <div class=" text-center mb-3 mt-4 ml-3" v-show="showResetFilterButton && (searchSite.length > 0 || searchAgent.length > 0)">
              <button class="btn btn-lg btn-primary mt-3" @click="clearFilterAndFetchMeetings">{{ $t('WaitingRoom.reset filter') }}</button>
            </div>
          </div>
      </div>

      <div ref="identificationPanel" class="identication-panel" v-show="($store.getters.getIdentificationStarted && participantIdentifications.length)" :class="[showIdentificationPopup ? 'container flex-grow-1 py-3' : 'd-none']">
        <div v-for="(participantIdentification, idx) of participantIdentifications" :key="idx"  class="row justify-content-end">
          <div class="card">
            <div class="card-body" style="width: 400px; height: 300px;">
              <div style="height: 100%;">
                <div style="text-align: center">{{ participantIdentification.name }}</div>
                <div>
                  {{ $t('MeetingRoom.BSN Number') }}:
                  <div style="margin-top: 10px;">
                    <div style="background: #d4fdda; border: #07a41b solid 2px" class="text-white p-2 border custom-border d-flex align-items-center">
                      <span style="background-color: #08ca22; border-radius: 50%; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center">
                        <font-awesome-icon :icon="['fal', 'check']" />
                      </span>
                      <span style="color: green; margin-left: 10px;">{{ participantIdentification.participantBSN }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <button class="btn btn-success" @click="acceptDataIdentification">{{ $t('MeetingRoom.BSN Correct') }}</button> <button class="btn btn-danger" @click="rejectDataIdentification">{{ $t('MeetingRoom.BSN Not Correct') }}</button></div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <weseedo-footer v-if="$route.name === 'waiting_room'"></weseedo-footer>

    <!-- toast meeting url participant copied -->
    <div ref="meetingUrlParticipantToast" id="meeting-url-participant-copied-toast" class="toast hide position-absolute z-index-5 min-w-px-250 h-px-60 opacity-10 m-0 bg-lightgray" role="alert" aria-live="assertive" aria-atomic="true" data-delay="800">
      <div class="toast-body d-flex align-items-center justify-content-center text-center font-size-rem-12 min-w-px-350 h-px-60">
        {{ $t('WaitingRoom.Link copied') }}
      </div>
    </div>

    <div id="modal-takeover-appointment" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('WaitingRoom.Takeover meeting') }}</h5>
            <button type="button" class="close" data-dismiss="modal" :aria-label="$t('Accessibility/General.Close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ $t('WaitingRoom.Are you sure you want to take over the appointment') }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" ref="meetingTakeOverCancel" data-dismiss="modal">{{ $t('WaitingRoom.No, cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="takeOverAppointment($event)">{{ $t('WaitingRoom.Yes, take over') }} <span ref="meetingTakeOverCounter"></span></button>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-edit-meeting" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('WaitingRoom.What do you want to edit?') }}</h5>
            <button type="button" class="close" data-dismiss="modal" :aria-label="$t('Accessibility/General.Close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ $t('WaitingRoom.This appointment is part of a recurring meeting, do you want to edit the recurring meeting or this meeting only?') }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" ref="meetingEditRecurring" @click="editMeetingDialogRecurring">{{ $t('WaitingRoom.Edit recurring meeting') }}</button>
            <button type="button" class="btn btn-primary" ref="meetingEditSpecific" @click="editMeetingDialogSpecific">{{ $t('WaitingRoom.Edit only this meeting') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-cancel-appointment" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('WaitingRoom.Cancel appointment') }}</h5>
            <button type="button" class="close" data-dismiss="modal" :aria-label="$t('Accessibility/General.Close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              <template v-if="cancelMeeting === null || typeof cancelMeeting.chat_duration === 'undefined' || cancelMeeting.chat_duration === null || cancelMeeting.chat_duration < 1">
                {{ $t('WaitingRoom.Are you sure you want to cancel the appointment') }}
              </template>
              <template v-else>
                {{ $t('WaitingRoom.Are you sure you want to delete the appointment') }}
              </template>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('WaitingRoom.No, keep it') }}</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" @click="cancelAppointment">
              <template v-if="cancelMeeting === null || typeof cancelMeeting.chat_duration === 'undefined' || cancelMeeting.chat_duration === null || cancelMeeting.chat_duration < 1">
                {{ $t('WaitingRoom.Yes, cancel') }}
              </template>
              <template v-else>
                {{ $t('WaitingRoom.Yes, delete') }}
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-cancel-recurring-appointment" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('WaitingRoom.Cancel recurring appointment') }}</h5>
            <button type="button" class="close" data-dismiss="modal" :aria-label="$t('Accessibility/General.Close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
               {{ $t('WaitingRoom.This appointment is part of a recurring meeting, do you want to cancel the recurring meeting or this meeting only?') }}
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" ref="meetingEditRecurring" data-dismiss="modal" @click="cancelAppointmentDialogRecurring">{{ $t('WaitingRoom.Cancel recurring meeting') }}</button>
            <button type="button" class="btn btn-primary" ref="meetingEditSpecific" data-dismiss="modal" @click="cancelAppointment">{{ $t('WaitingRoom.Cancel only this meeting') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-edit-waiting-room-message" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('WaitingRoom.Edit waiting room text') }}</h5>
            <button type="button" class="close" data-dismiss="modal" :aria-label="$t('Accessibility/General.Close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form id="waitingRoomMessageForm" method="POST" @submit.prevent="saveWaitingRoomMessage">
              <label for="message_waiting_room">{{ $t('WaitingRoom.The message below will be visible to all clients in the waiting room') }}</label>
              <textarea class="form-control h-px-80" id="message_waiting_room" name="message_waiting_room" ref="message_waiting_room" maxlength="250" v-model="editMeetingWaitingRoomNewMessage" :placeholder="$t('WaitingRoom.I am ready in a minute')"></textarea>
              <div class="d-flex font-size-rem-8">{{ $t('WaitingRoom.Characters left') }}: {{ 250 - editMeetingWaitingRoomNewMessage.length }}</div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('WaitingRoom.Cancel') }}</button>
            <button type="submit" class="btn btn-primary" form="waitingRoomMessageForm">
              {{ $t('WaitingRoom.Ok, save') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-meeting-comment" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('WaitingRoom.Comment') }}</h5>
            <button type="button" class="close" data-dismiss="modal" :aria-label="$t('Accessibility/General.Close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ meetingComment }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" ref="meetingTakeOverCancel" data-dismiss="modal">{{ $t('WaitingRoom.Close') }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal alert errors or messages -->
    <div id="modal-alert-whiteboard-backup" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('MeetingRoom.Unsaved changes') }}</h5>
            <button type="button" class="close" data-dismiss="modal" :aria-label="$t('Accessibility/General.Close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
           <p>{{ $t('MeetingRoom.Your unsaved drawings will be lost') }}</p>
          </div>
          <div class="modal-footer">
           <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="saveWhiteboardScreenshot()">{{ $t('MeetingRoom.Save') }}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('MeetingRoom.Close') }}</button>
          </div>
        </div>
      </div>
    </div>

    <modal-call-agent v-if="$route.name === 'waiting_room'"></modal-call-agent>
    <modal-feedback-form v-if="$root.getFeedbackForm !== null" :form="$root.getFeedbackForm"></modal-feedback-form>

  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import AppointmentSkeleton from './AppointmentSkeleton.vue'
import { UtilMixin } from './mixins/utils/UtilMixin'
import SiteAgentSelector from './personal/WaitingRoomFilter.vue'
export default {
  name: 'WaitingRoom',
  data () {
    return {
      currentDate: new Date(),
      currentDateInterval: null,
      editMeeting: null,
      meetings: [],
      getMeetingsInterval: null,
      cancelMeeting: null,
      takeOverMeeting: null,
      editMeetingWaitingRoomMessage: {},
      editMeetingWaitingRoomNewMessage: '',
      accountUsers: [],
      searchAgent: '',
      searchSite: '',
      joinMeetingLoading: false,
      showIdentificationPopup: false,
      meetingComment: '',
      formError: {
        showStartTimeRequired: false,
        showStartTimeFuture: false,
        showEndTimeRequired: false
      },
      datePickerStatTimeOptions: {
        format: this.$t('Formats.DateTime'),
        sideBySide: true,
        locale: this.$i18n.locale,
        stepping: 5,
        useCurrent: false,
        ignoreReadonly: true,
        icons: {
          time: 'fal fa-clock',
          date: 'fal fa-calendar',
          up: 'fal fa-chevron-up',
          down: 'fal fa-chevron-down',
          previous: 'fal fa-chevron-left',
          next: 'fal fa-chevron-right',
          clear: 'fal fa-trash',
          close: 'fal fa-times'
        },
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'auto'
        }
      },
      datePickerEndTimeOptions: {
        format: this.$t('Formats.DateTime'),
        sideBySide: true,
        locale: this.$i18n.locale,
        stepping: 5,
        useCurrent: false,
        ignoreReadonly: true,
        icons: {
          time: 'fal fa-clock',
          date: 'fal fa-calendar',
          up: 'fal fa-chevron-up',
          down: 'fal fa-chevron-down',
          previous: 'fal fa-chevron-left',
          next: 'fal fa-chevron-right',
          clear: 'fal fa-trash',
          close: 'fal fa-times'
        },
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'auto'
        }
      },
      datePickersReadOnly: window.DetectRTC.isMobileDevice,
      refreshMeetingsDelay: false,
      whiteboardBackup: null,
      url: '',
      isFetching: false,
      pagination: {
        'totalDocs': 0,
        'limit': 10,
        'page': 0,
        'totalPages': 0,
        'pagingCounter': 0,
        'hasPrevPage': false,
        'hasNextPage': false,
        'prevPage': 0,
        'nextPage': 1
      },
      siteAgentList: [],
      isLoadingMore: false, // used to control when to animate the newly loading data into the screen, relevant only when load more button is clicked
      showResetFilterButton: true,
      participantIdentifications: []
    }
  },
  mixins: [ UtilMixin ],
  components: {
    AppointmentSkeleton,
    SiteAgentSelector
  },
  methods: {
    validateRights () {
      if (this.$store.getters.getProduct === 'direct') {
        this.$router.push({ name: 'appointment' })
      }
    },
    async acceptDataIdentification () {
      console.log('accept data from identificationPanel')
      const identification = { 'identifications': [{ 'type': 'result', 'buttonClicked': 'accept' }] }
      const acceptData = await this.$axios.post(this.$axios.routes.meeting_personal + '/' + this.$store.getters.getLastMeetingInfo.id + '/finish-identification', identification)
      if (typeof acceptData.status !== 'undefined' && acceptData.status === 200) {
        console.log('acceptData ok', acceptData)
      } else {
        console.log('acceptData not ok', acceptData)
      }
      this.showIdentificationPopup = false
    },
    async rejectDataIdentification () {
      console.log('reject data from identificationPanel')
      const identification = { 'identifications': [{ 'type': 'result', 'buttonClicked': 'reject' }] }
      const rejectData = await this.$axios.post(this.$axios.routes.meeting_personal + '/' + this.$store.getters.getLastMeetingInfo.id + '/finish-identification', identification)
      if (typeof rejectData.status !== 'undefined' && rejectData.status === 200) {
        console.log('rejectData ok', rejectData)
      } else {
        console.log('rejectData not ok', rejectData)
      }
      this.showIdentificationPopup = false
    },
    participantToolTip (participant, index) {
      if (participant.deleted_at !== null) {
        return this.$t('WaitingRoom.{NAME} has cancelled the meeting', { NAME: participant.name })
      }

      if (participant.email && participant.phone && (index > 0 || !participant.user)) {
        return participant.email + ' - ' + participant.phone
      } else if (participant.email && !participant.phone && (index > 0 || !participant.user)) {
        return participant.email
      } else if (!participant.email && participant.phone && (index > 0 || !participant.user)) {
        return participant.phone
      }
    },
    clearFilterAndFetchMeetings () {
      this.searchAgent = ''
      this.searchSite = ''
      this.getSiteUsers()
    },
    async getMeetings (nextPage = 1) {
      let betaQuery = ''
      if (this.$root.isBeta) {
        betaQuery = '&beta=true'
      }

      this.isFetching = true
      this.showResetFilterButton = true
      const meetingURL = this.updateQueryParams(this.$axios.routes.meeting_personal + `/paginate`, {
        is_appointment: true,
        sort: 'asc',
        pageSize: 10,
        page: nextPage ?? 1,
        ...(this.searchSite && { company: this.searchSite }),
        ...(this.searchAgent && { user: this.searchAgent }),
        ...betaQuery
      })

      const savedMeetings = await this.$axios.get(meetingURL)

      if (nextPage === 1) {
        this.meetings = []
      }

      if (typeof savedMeetings.status !== 'undefined' && savedMeetings.status === 200) {
        this.meetings = this.meetings.concat(savedMeetings.data.result.data)
        this.pagination = savedMeetings.data.result.pagination
        this.isFetching = false
        this.isLoadingMore = false
      } else {
        this.meetings = []
      }
      // at this point we have one site and it has no meetings, there is no need to clear filters so we hide the button allowing the user
      // to create a new appointment

      if (this.meetings.length === 0 && this.$store.getters.getUserSites.length === 1 && this.searchSite && !this.searchAgent) {
        this.showResetFilterButton = false
      }
    },
    getParticipants (participants) {
      let showAgentLink = false
      // if one of the participants have a link it will also visible for agent
      for (const participant of participants) {
        if (!participant.email && !participant.phone && !participant.user) {
          showAgentLink = true
          break
        }
      }
      return participants.map((participant) => {
        if (participant.user) {
          participant.showLink = showAgentLink
        } else {
          participant.showLink = !participant.email && !participant.phone
        }
        return participant
      })
    },
    showJoinMeetingButton (participants) {
      const onlineParticipants = participants.filter((participant) => {
        return typeof participant.user === 'undefined' && participant.status === 'online'
      })
      return onlineParticipants.length > 0
    },
    async confirmTakeOverMeeting (meeting) {
      this.takeOverMeeting = JSON.parse(JSON.stringify(meeting))
      this.takeOverMeeting.participants[0].user = this.$store.getters.getUser.id
      this.$('#modal-takeover-appointment').modal({ backdrop: 'static', keyboard: false }, 'show')
    },
    async takeOverAppointment (event) {
      event.target.disabled = true
      this.$refs['meetingTakeOverCancel'].disabled = true

      this.$webrtc.takeOver(this.takeOverMeeting.id, this.$store.getters.getUser.id)

      let counter = 6
      let counterInterval = setInterval(() => {
        counter--
        this.$refs['meetingTakeOverCounter'].innerHTML = '(' + counter + ')'
        if (counter === 0) {
          clearInterval(counterInterval)
          event.target.disabled = false
          this.$refs['meetingTakeOverCancel'].disabled = false
          this.$('#modal-takeover-appointment').modal('hide')
          this.$refs['meetingTakeOverCounter'].innerHTML = ''
          this.joinMeeting(this.takeOverMeeting)
        }
      }, 1000)
    },
    async joinMeeting (meeting) {
      console.log('Start meeting button click')

      this.joinMeetingLoading = meeting.room_id
      this.$store.commit('setMeeting', meeting)
      this.$store.commit('setIsMeetingHost', true)

      this.$webrtc.setP2P(meeting.participants.length <= 3)

      if (!meeting.participants[0].user) {
        this.$webrtc.takeOver(meeting.id, this.$store.getters.getUser.id)
      }

      this.$root.webrtcJoin = {
        room_id: meeting.room_id,
        product: meeting.product,
        stream2way: meeting.stream_camera_two_way
      }

      await this.$router.push({ name: 'meeting', query: this.$route.query })
    },
    cancelDialog (meeting) {
      this.cancelMeeting = meeting
      if (!meeting.recurrence) {
        this.$('#modal-cancel-appointment').modal({ backdrop: 'static', keyboard: false }, 'show')
      } else {
        this.$('#modal-cancel-recurring-appointment').modal({ backdrop: 'static', keyboard: false }, 'show')
      }
    },
    waitingRoomMessageDialog (meeting) {
      this.editMeetingWaitingRoomMessage = meeting
      this.editMeetingWaitingRoomNewMessage = meeting.waiting_room_text ? meeting.waiting_room_text : ''
      this.$('#modal-edit-waiting-room-message').modal({ backdrop: 'static', keyboard: false }, 'show')
    },
    commentDialog (meeting) {
      this.meetingComment = meeting.comment ? meeting.comment : ''
      this.$('#modal-meeting-comment').modal({ backdrop: 'static', keyboard: false }, 'show')
    },
    saveWaitingRoomMessage () {
      const formData = {
        waiting_room_text: this.editMeetingWaitingRoomNewMessage
      }
      this.$axios.patch(this.$axios.routes.meeting_personal + '/' + this.editMeetingWaitingRoomMessage.id, formData)
      this.$('#modal-edit-waiting-room-message').modal('hide')
    },
    editAppointment (meeting) {
      if (!meeting.recurrence) {
        this.$router.push({ name: 'appointment', params: { editMeeting: meeting, editMeetingType: 'specific' } })
      } else {
        this.editMeetingDialog(meeting)
      }
    },
    editMeetingDialog (meeting) {
      this.editMeeting = meeting
      this.$('#modal-edit-meeting').modal({ backdrop: 'static', keyboard: false }, 'show')
    },
    editMeetingDialogSpecific () {
      this.$router.push({ name: 'appointment', params: { editMeeting: this.editMeeting, editMeetingType: 'specific' } })
      this.editMeeting = null
      this.$('#modal-edit-meeting').modal('hide')
    },
    editMeetingDialogRecurring (meeting) {
      this.$router.push({ name: 'appointment', params: { editMeeting: this.editMeeting, editMeetingType: 'recurring' } })
      this.editMeeting = null
      this.$('#modal-edit-meeting').modal('hide')
    },
    async cancelAppointmentDialogRecurring (meeting) {
      const deleteMeeting = await this.$axios.delete(this.$axios.routes.meeting_personal_recurring, this.cancelMeeting.recurrence.id)

      if (typeof deleteMeeting.status !== 'undefined' && deleteMeeting.status === 200) {
        await this.getMeetings()
        this.$eventBus.$emit('setAppointmentRemoved')
      }
    },
    async cancelAppointment () {
      const deleteMeeting = await this.$axios.delete(this.$axios.routes.meeting_personal, this.cancelMeeting.id)

      if (typeof deleteMeeting.status !== 'undefined' && deleteMeeting.status === 200) {
        this.meetings = this.meetings.filter((obj) => {
          return obj.id !== this.cancelMeeting.id
        })

        if (this.meetingList.length === 0) {
          this.searchAgent = ''
          this.searchSite = ''
        }

        this.$eventBus.$emit('setAppointmentRemoved')
      }
    },
    isMeetingExpired (meeting) {
      if (typeof meeting.room_available_from === 'undefined' || typeof meeting.room_available_till === 'undefined') {
        return true
      }

      let roomAvailableFrom = new Date(meeting.room_available_from).getTime() / 1000
      let roomAvailableTill = new Date(meeting.room_available_till).getTime() / 1000

      if (roomAvailableTill - roomAvailableFrom < 3600) {
        roomAvailableTill = roomAvailableFrom + 3600
      }
      return (Math.round(this.currentDate.getTime() / 1000)) >= roomAvailableTill
    },
    copyMeetingUrlParticipant (event, url) {
      navigator.clipboard.writeText(url)

      event.stopPropagation()

      const positionTop = event.pageY + 15
      let positionLeft = event.pageX - (this.$(this.$refs['meetingUrlParticipantToast']).outerWidth() / 2)

      // make always sure it will be 10 pixels from left
      if (positionLeft < 10) {
        positionLeft = 10
      }

      this.$(this.$refs['meetingUrlParticipantToast']).css({ 'top': positionTop + 'px', 'left': positionLeft + 'px' })

      this.$(this.$refs['waitingRoomContainer']).find('#meeting-url-participant-copied-toast').toast('show')

      console.log('Copied URL to clipboard: ' + url)
    },
    onVisitorOnline (username, action, agentId, participantBSN) {
      this.showIdentificationPopup = true
      setTimeout(() => {
        this.meetings = this.meetings.filter((meeting) => {
          meeting.participants.filter((participant) => {
            if (participant.username === username) {
              participant.status = 'online'
              const findParticipantBSN = this.participantIdentifications.find(ele => ele.participantBSN === participantBSN)
              if (!findParticipantBSN && participantBSN) {
                this.participantIdentifications.push({ participantBSN, name: participant.name })
              }
            }
            return participant
          })
          return meeting
        })
        if (this.$refs['identificationPanel']) {
          this.$refs['identificationPanel'].scrollIntoView()
        }
      }, this.refreshMeetingsDelay ? 2000 : 0)
    },
    onVisitorOffline (username) {
      this.showIdentificationPopup = false
      setTimeout(() => {
        this.meetings = this.meetings.filter((meeting) => {
          meeting.participants.filter((participant) => {
            if (participant.username === username) {
              participant.status = 'offline'
            }
            return participant
          })
          return meeting
        })
      }, this.refreshMeetingsDelay ? 2000 : 0)
    },
    async onMeetingUpdated (id) {
      let betaQuery = ''
      if (this.$root.isBeta) {
        betaQuery = '?beta=true'
      }

      const meeting = await this.$axios.get(this.$axios.routes.meeting_personal + '/' + id + betaQuery)

      if (typeof meeting.status !== 'undefined' && meeting.status === 200) {
        // check if meeting exists in array already
        const findMeeting = this.meetings.filter((obj) => {
          return obj.id === id
        })

        // if meeting exists, remove it anyway
        if (findMeeting.length !== 0) {
          if (findMeeting[0].site.id !== meeting.data.result.site.id) {
            this.searchSite = ''
          }
          if (findMeeting[0].participants[0].id !== meeting.data.result.participants[0].id) {
            this.searchAgent = ''
          }

          this.meetings = this.meetings.filter((obj) => {
            return obj.id !== id
          })
        } else {
          this.searchAgent = ''
          this.searchSite = ''
        }

        // add the new meeting
        this.meetings.push(meeting.data.result)
      } else {
        // meeting is removed, remove it from meetings array
        this.meetings = this.meetings.filter((obj) => {
          return obj.id !== id
        })

        // if no meetings present after filtering, clear filters
        if (this.meetingList.length === 0) {
          this.searchAgent = ''
          this.searchSite = ''
        }
      }

      this.meetings = this.meetings.sort((a, b) => new Date(a.room_available_from) - new Date(b.room_available_from))
    },
    onWhiteboardBackupScreenshot (data) {
      this.whiteboardBackup = data
      this.$(this.$refs['waitingRoomContainer']).find('#modal-alert-whiteboard-backup').modal({
        backdrop: 'static',
        keyboard: false
      }, 'show')
    },
    saveWhiteboardScreenshot () {
      if (this.whiteboardBackup) {
        saveAs(this.whiteboardBackup.blob, this.whiteboardBackup.fileName)
      }
      this.whiteboardBackup = null
    },
    async loadMore () {
      // Extract nextPage from the loadMore div's data attribute
      if (this.pagination.hasNextPage === false) {
        return
      }
      // Call getMeetings with nextPage
      this.isLoadingMore = true
      await this.getMeetings(parseInt(this.pagination.nextPage, 10))
    },
    async getSiteUsers () {
      try {
        await this.getMeetings(1)
        if (!this.searchSite || !['assistent', 'assistent_video'].includes(this.$store.getters.getUser.role)) {
          this.siteAgentList = []
          if (this.$store.getters.getUserSites.length > 0) {
            const siteIds = this.$store.getters.getUserSites?.map(site => site?.id)?.filter(Boolean)
            const response = await this.$axios.post('/management/sites/users', {
              siteIds
            })
            const allUsers = {}
            for (const result of response.data.result) {
              const users = result.users?.filter((user) => ['agent', 'assistent_video'].includes(user.role)) ?? []
              users.forEach(user => { allUsers[user.id] = user })
            }
            this.siteAgentList = Object.values(allUsers)
          }
          return
        }
        const response = await this.$axios.get(`/management/sites/users/${this.searchSite}`)
        if (typeof response.status !== 'undefined' && response.status === 200) {
          this.siteAgentList = response.data.result?.users?.filter((user) => ['agent', 'assistent_video'].includes(user.role)) ?? []
        }
      } catch (err) {
        // Log the error for debugging purposes
        console.error('Error fetching site users:', err)
        // Provide a user-friendly error message, ensure err.response and err.response.data are defined
        const errorMessage = err.response && err.response.data ? err.response.data.error_message : 'An unexpected error occurred'
        throw new Error(errorMessage)
      }
    }
  },
  computed: {
    agentList () {
      if (typeof this.accountUsers === 'undefined' || this.accountUsers.length === 0) {
        return []
      }

      return this.accountUsers.filter(function (user) {
        return ['agent', 'assistent_video'].includes(user.role)
      })
    },
    meetingList () {
      if (typeof this.meetings === 'undefined' || this.meetings === null || this.meetings.length === 0) {
        return []
      }
      let meetings = this.meetings.filter((meeting) => {
        const notExpiredOrIsActive = !this.isMeetingExpired(meeting) || meeting.is_currently_active
        let siteMatch = true
        let agentMatch = true

        if (this.searchSite !== '') {
          if (this.searchSite !== meeting.site.id) {
            siteMatch = false
          }
        }
        if (this.searchAgent !== '') {
          if (this.searchAgent !== meeting.participants[0].user) {
            agentMatch = false
          }
        }
        return siteMatch && agentMatch && notExpiredOrIsActive
      })
      // when the first meeting is started already, set the first NOT started meeting in top
      if (meetings.length > 1 && typeof meetings[0].chat_duration !== 'undefined' && meetings[0].chat_duration !== null && meetings[0].chat_duration > 1) {
        for (let meetingKey in meetings) {
          if (meetings.hasOwnProperty(meetingKey)) {
            meetingKey = Number(meetingKey)

            if (typeof meetings[meetingKey].chat_duration === 'undefined' || meetings[0].chat_duration === null || meetings[0].chat_duration < 1) {
              const splicedMeeting = meetings[meetingKey]

              meetings.splice(meetingKey, 1)
              meetings.unshift(splicedMeeting)

              break
            }
          }
        }
      }
      return meetings
    },
    meetingListNoFilter () {
      if (typeof this.meetings === 'undefined' || this.meetings === null || this.meetings.length === 0) {
        return []
      }

      let meetings = this.meetings.filter((meeting) => {
        return !this.isMeetingExpired(meeting) || meeting.is_currently_active
      })

      return meetings
    }
  },
  watch: {
    meetings () {
      if (Array.isArray(this.meetings) && this.meetings.length === 0) {
        this.$eventBus.$emit('setNoScheduledAppointments', true)
      } else {
        this.$eventBus.$emit('setNoScheduledAppointments', false)
      }
    },
    isLoadingMore (newVal) {
      if (newVal === true) {
        this.$nextTick(() => {
          if (this.$refs.loadMore) {
            this.$refs.loadMore.scrollIntoView({ behavior: 'smooth' })
          }
        })
      }
    }
  },
  async activated () {
    this.validateRights()
    // reset meetings array because this page just got activated and needs clean data
    this.meetings = []
    await this.getSiteUsers()
    this.refreshMeetingsDelay = typeof this.$route.params.refreshMeetingsDelay !== 'undefined' && this.$route.params.refreshMeetingsDelay === 'true'

    setTimeout(() => {
      this.refreshMeetingsDelay = false
    }, 2000)

    this.joinMeetingLoading = false

    clearInterval(this.currentDateInterval)
    this.currentDateInterval = setInterval(() => {
      this.currentDate = new Date()
    }, 10000)
  },
  deactivated () {
    clearInterval(this.currentDateInterval)
    this.$store.commit('setIdentificationStarted', false)
  },
  destroyed () {
    this.$webrtc.EventBus.$off('onVisitorOnline', this.onVisitorOnline)
    this.$webrtc.EventBus.$off('onVisitorOffline', this.onVisitorOffline)
    this.$webrtc.EventBus.$off('onMeetingUpdated', this.onMeetingUpdated)
    this.$eventBus.$off('whiteboardBackupScreenshot', this.onWhiteboardBackupScreenshot)
    this.$store.commit('setIdentificationStarted', false)
  },
  mounted () {
    console.log('Mounted WaitingRoom')
    this.$('#search_site').select2({
      'width': '100%',
      'language': {
        'noResults': () => {
          return this.$t('WaitingRoom.No results found')
        }
      }
    })
    this.$('#search_agent').select2({
      'width': '100%',
      'language': {
        'noResults': () => {
          return this.$t('WaitingRoom.No results found')
        }
      }
    })
    this.$webrtc.EventBus.$on('onVisitorOnline', this.onVisitorOnline)
    this.$webrtc.EventBus.$on('onVisitorOffline', this.onVisitorOffline)
    this.$webrtc.EventBus.$on('onMeetingUpdated', this.onMeetingUpdated)
    this.$eventBus.$on('whiteboardBackupScreenshot', this.onWhiteboardBackupScreenshot)
  }
}
</script>

<style lang="scss" scoped>
  #loadMore {
    display: flex;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    p {
      text-align: center;
      background-color: #f9f9f9;
       border-top: 1px solid #ddd;
       display: block;
       padding: 10px 0;
       border-radius: 5px;
    }
  }
  .fade-enter-active, .fade-leave-active {
      transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }

  .identication-panel {
    position: fixed;
    bottom: 100px;
    right: 10px;
  }

</style>
