<template>
    <transition name="fade">
        <div class="dialog" :style="dialogStyle" ref="scriptixDialog">
            <div class="dialog-body" :class="{ expanded: isDialogContentVisible }" ref="dialogBody">
                <div class="dialog-header">
                    <h6>{{ $t('Modals/ScriptixTranscription.Title') }}</h6>
                    <div>
                        <button type="button" class="expand" @click="toggleDialogContent" aria-label="expand dialog"
                            ref="expandButton">
                            <font-awesome-icon :icon="['fal', 'angle-up']" class="fa-xs expandButton" />
                        </button>
                        <button type="button" class="close" @click="$emit('close')"
                            :aria-label="$t('Accessibility/General.Close')">
                            <font-awesome-icon :icon="['fal', 'xmark']" class="fa-xs" />
                        </button>
                    </div>
                </div>
                <hr>
                <transition name="fade">
                    <div class="dialog-content" v-if="isDialogContentVisible">
                        <p v-html="$t('Modals/ScriptixTranscription.description')"></p>
                        <div class="dialog-footer">
                            <div class="loader" :style="loaderStyle" v-if="notification.progress > 0"></div>
                            <button @click="triggerDownloadAndDelete" class="primaryDownload btn btn-lg btn-primary" :disabled="notification.progress > 0">
                                <span>{{ $t('Modals/ScriptixTranscription.Download') }}</span>
                            </button>
                            <button @click="$emit('close')" class="btn btn-secondary">{{
                                $t('Modals/ScriptixTranscription.Close') }}</button>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  data () {
    return {
      dialogTop: 0,
      isDialogContentVisible: false
    }
  },
  props: {
    notification: {
      type: Object,
      required: false
    },
    notificationCount: {
      type: Number,
      required: false,
      default: 0
    },
    index: {
      type: Number,
      required: false
    }
  },
  computed: {
    loaderStyle () {
      // Compute the circular loader style based on the progress value
      return {
        background: `conic-gradient(#9fa1a3 ${this.notification.progress * 3.6}deg, #f0f3f5 0deg)`,
        display: this.notification.progress < 100 ? 'block' : 'none'
      }
    },
    dialogStyle () {
      const dialogBodyHeight = 60

      return {
        transform: `translate(0px, -${dialogBodyHeight * this.index + this.index}px)`,
        zIndex: this.isDialogContentVisible ? this.highestZIndex : 5
      }
    },
    highestZIndex () {
      // Find the highest zIndex among all dialogs
      const dialogs = document.querySelectorAll('.dialog')
      let highest = 5
      dialogs.forEach(dialog => {
        const zIndex = parseInt(window.getComputedStyle(dialog).zIndex, 10)
        if (zIndex > highest) {
          highest = zIndex
        }
      })
      return highest + 1
    }
  },
  methods: {
    triggerDownloadAndDelete () {
      this.$emit('triggerScriptixTranscription', this.notification.data['x-scriptix-session'], this.notification.data['x-scriptix-document'], this.index)
    },
    toggleDialogContent () {
      this.isDialogContentVisible = !this.isDialogContentVisible
      this.$refs.expandButton.style.transform = this.isDialogContentVisible ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  },
  watch: {
    'notification.progress': function (newVal) {
      if (newVal === 100) {
        this.$emit('close')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: 600px) {
    .dialog {
        width: 60% !important;
    }
}

@media (min-width: 1200px) {
    .dialog {
        width: 26% !important;
        right: 1rem !important;
        left: initial !important;
    }
}

.dialog {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    bottom: 120px;
    z-index: 5;
    border-radius: 5px;

    left: 1.5rem;

    .close {
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
}

.dialog-body {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    height: 60px;
    width: 100%;
    overflow: hidden;
    transition: height 0.5s ease;

    &.expanded {
        height: auto;
    }

    .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        h6 {
            margin: 0;
        }

        div {
            width: 15%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: flex-end;
        position:relative;
        gap: 1rem;

        button {
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .loader {
      position: absolute;
      right: 265px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: conic-gradient(#9fa1a3 0deg, #f0f3f5 0deg);
      transition: background 0.3s ease; /* Smooth transition for loader */
      top: 35%;
      border: 2px solid #9fa1a3
    }
}
</style>
