<template>
  <div class="container-fluid d-flex flex-column p-0">

    <weseedo-header></weseedo-header>

    <div class="container py-4" v-if="requestPosted === false && tokenValid === true">
      <div class="row justify-content-center">
        <div class="col-10 col-sm-8 col-md-6 col-lg-5">
          <h2 class="font-weight-normal text-center border-bottom pb-2 mb-3">{{ $t('ResetPassword.Password setup') }}</h2>

          <div :class="password.length < 8 ? 'text-danger' : 'text-success'" class="d-flex">
            <span class="d-inline-flex align-items-center w-px-30 font-size-rem-12">
              <font-awesome-icon :icon="['fal', 'xmark']" v-if="password.length < 8" />
              <font-awesome-icon :icon="['fal', 'check']" v-if="password.length >= 8" />
            </span>
            <span class="d-inline-flex align-items-center">{{ $t('ResetPassword.Minimum 8 characters') }}</span>
          </div>
          <div :class="password === password.toLowerCase() ? 'text-danger' : 'text-success'" class="d-flex">
            <span class="d-inline-flex align-items-center w-px-30 font-size-rem-12">
              <font-awesome-icon :icon="['fal', 'xmark']" v-if="password === password.toLowerCase()" />
              <font-awesome-icon :icon="['fal', 'check']" v-if="password !== password.toLowerCase()" />
            </span>
            <span class="d-inline-flex align-items-center">{{ $t('ResetPassword.Minimum 1 uppercase') }}</span>
          </div>
          <div :class="password === password.toUpperCase() ? 'text-danger' : 'text-success'" class="d-flex">
            <span class="d-inline-flex align-items-center w-px-30 font-size-rem-12">
              <font-awesome-icon :icon="['fal', 'xmark']" v-if="password === password.toUpperCase()" />
              <font-awesome-icon :icon="['fal', 'check']" v-if="password !== password.toUpperCase()" />
            </span>
            <span class="d-inline-flex align-items-center">{{ $t('ResetPassword.Minimum 1 lowercase') }}</span>
          </div>
          <div :class="!/\d/.test(password) ? 'text-danger' : 'text-success'" class="d-flex">
            <span class="d-inline-flex align-items-center w-px-30 font-size-rem-12">
              <font-awesome-icon :icon="['fal', 'xmark']" v-if="!/\d/.test(password)" />
              <font-awesome-icon :icon="['fal', 'check']" v-if="/\d/.test(password)" />
            </span>
            <span class="d-inline-flex align-items-center">{{ $t('ResetPassword.Minimum 1 number') }}</span>
          </div>

          <form method="post" @submit.prevent="resetPassword" class="mt-3">
            <div class="form-group">
              <label for="password" class="mb-0 font-weight-bold">{{ $t('ResetPassword.Password') }}</label>
              <vue-password v-model="password"
                            classes="form-control"
                            disable-strength
                            spellcheck="false"
                            id="password"
                            ref="password"
                            @blur="disableViewPassword('password')"
              />
            </div>
            <div class="form-group">
              <label for="password" class="mb-0 font-weight-bold">{{ $t('ResetPassword.Repeat password') }}</label>
              <vue-password v-model="passwordRepeat"
                            classes="form-control"
                            disable-strength
                            spellcheck="false"
                            id="password_repeat"
                            ref="password_repeat"
                            disableToggle
              />
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center mb-1" v-show="this.passwordRepeat.length > 0 && this.password !== this.passwordRepeat">
              {{ $t('ResetPassword.Password does not match') }}
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center mb-1" v-show="showError">
              {{ $t('ResetPassword.Reset password failed') }}
            </div>
            <div class="d-flex">
              <button type="submit" class="btn btn-lg btn-primary btn-block" :class="{ disabled: requestButtonDisabled }" :disabled="requestButtonDisabled" v-if="requestButtonLoading === false">{{ $t('ResetPassword.Set password') }}</button>
              <button type="submit" class="btn btn-lg btn-primary btn-block disabled" disabled="true" v-else><font-awesome-icon :icon="['fal', 'spinner']" spin role="presentation" /> {{ $t('ResetPassword.Sending') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="container py-4" v-else-if="requestPosted === true && tokenValid === true">
      <div class="row justify-content-center">
        <div class="d-flex flex-column col-10 col-md-8 col-lg-6 text-center">
          <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('ResetPassword.Password set') }}</h2>
          <p>{{ $t('ResetPassword.New password is set') }}</p>
        </div>
      </div>
      <div class="row justify-content-center px-4">
        <div class="col-12 col-sm-9 col-md-7 col-lg-5 text-center mt-4">
          <button type="button" class="btn btn-lg btn-primary mt-5" v-on:click="$router.push({ name: 'login' })">{{ $t('ResetPassword.Login') }}</button>
        </div>
      </div>
    </div>

    <div class="container py-4" v-else>
      <div class="row justify-content-center">
        <div class="d-flex flex-column col-10 col-md-8 col-lg-6 text-center">
          <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('ResetPassword.Link expired') }}</h2>
          <p>{{ $t('ResetPassword.The activation link is invalid or expired') }}</p>
        </div>
      </div>
      <div class="row justify-content-center px-4">
        <div class="col-12 col-sm-9 col-md-7 col-lg-5 text-center mt-4">
          <button type="button" class="btn btn-lg btn-primary mt-5" v-on:click="$router.push({ name: 'forgot_password' })">{{ $t('ResetPassword.Try again') }}</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Forgot',
  data () {
    return {
      password: '',
      passwordRepeat: '',
      tokenValid: null,
      requestButtonDisabled: true,
      requestButtonLoading: false,
      requestPosted: false,
      showError: false
    }
  },
  methods: {
    disableViewPassword (ref) {
      if (this.$refs[ref].type === 'text') {
        this.$refs[ref].togglePassword(false)
      }
    },
    async resetPassword () {
      this.requestButtonLoading = true
      this.showError = false

      let postData = {
        password: this.password
      }

      const resetPassword = await this.$axios.post(this.$axios.routes.reset_password + '/' + this.$route.params.token, postData)

      if (typeof resetPassword.data !== 'undefined' && typeof resetPassword.data.success !== 'undefined' && resetPassword.data.success === true) {
        this.requestPosted = true
      } else {
        this.showError = true
      }

      this.requestButtonLoading = false
    }
  },
  computed: {
    watchPassword () {
      return [this.password, this.passwordRepeat]
    }
  },
  watch: {
    watchPassword () {
      this.showError = false
      this.requestButtonDisabled = !(this.password.length >= 8 &&
        this.password !== this.password.toLowerCase() &&
        this.password !== this.password.toUpperCase() &&
        /\d/.test(this.password) &&
        this.password === this.passwordRepeat)
    }
  },
  async mounted () {
    this.$axios.logout(false)

    if (typeof this.$route.params.token === 'undefined') {
      this.$router.push({ name: 'home' })
    } else {
      const resetLinkValid = await this.$axios.get(this.$axios.routes.reset_password + '/' + this.$route.params.token)

      this.tokenValid = typeof resetLinkValid.data !== 'undefined' && typeof resetLinkValid.data.success !== 'undefined' && resetLinkValid.data.success === true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
