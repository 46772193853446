<template>
  <div class="d-flex flex-column justify-content-around align-items-center mb-3" :class="[typeof $store.getters.getNotificationBar.is_message !== 'undefined' && $store.getters.getNotificationBar.is_message === true && !$store.getters.getNotificationBarClosedIds.includes($store.getters.getNotificationBar.id) ? 'min-vh-20': 'flex-grow-1 min-h-px-80 max-vh-20']">
    <!-- notification bar -->
    <div role="alert" class="d-flex w-100 mb-3 bg-blue pt-3 pb-3 pl-4 pr-4 position-absolute position-top-0" style="z-index: 101 !important;" v-if="($router.enableNotificationBarRoutes.includes($route.name) || $store.getters.getMeetingDisconnectedAs === 'agent' || $store.getters.getMeetingEndedAs === 'agent' || $store.getters.getUser !== false) && typeof $store.getters.getNotificationBar.is_message !== 'undefined' && $store.getters.getNotificationBar.is_message === true && !$store.getters.getNotificationBarClosedIds.includes($store.getters.getNotificationBar.id) && $root.isAppReconnecting === false">

      <p class="mr-3"><span v-html="$store.getters.getNotificationBar.message"></span> <strong>| <a href="#" @click.prevent="closeNotificationBar($store.getters.getNotificationBar.id)">{{ $t('Header.Close message') }}</a></strong></p>

      <img v-if="$store.getters.getNotificationBar.message_type === 'informational'" class="d-flex ml-auto mt-auto mb-auto" src="../assets/info.svg" alt="Info">
      <img v-if="$store.getters.getNotificationBar.message_type === 'announcement'" class="d-flex ml-auto mt-auto mb-auto" src="../assets/announcement.svg" alt="Announcement">
      <img v-if="$store.getters.getNotificationBar.message_type === 'issue'" class="d-flex ml-auto mt-auto mb-auto" src="../assets/error.svg" alt="Issue">
    </div>

    <!-- reconnecting app -->
    <component :is="reconnectingComponent"></component>

    <!-- logo -->
    <div class="d-flex justify-content-center mt-auto" v-if="$store.getters.getProduct === 'direct' || $route.name !== 'meeting' || $store.getters.getUser !== false || $store.getters.getMeetingEndedAs === 'agent' || $store.getters.getMeetingDisconnectedAs === 'agent'">
      <img v-if="$store.getters.getUser === false && $store.getters.getMeeting !== false && typeof $store.getters.getMeeting.site !== 'undefined' && typeof $store.getters.getMeeting.site.logo !== 'undefined' && $store.getters.getMeeting.site.logo !== null" :src="$store.getters.getMeeting.site.logo" class="custom_logo" alt="Logo" />
      <img v-else-if="$store.getters.getUser !== false && typeof $store.getters.getUser.entity.logo !== 'undefined' && $store.getters.getUser.entity.logo !== null && typeof $store.getters.getUser.entity.custom_domain !== 'undefined' && $store.getters.getUser.entity.custom_domain === true && !hostname.includes('weseedo.nl')" :src="$store.getters.getUser.entity.logo" class="custom_logo" alt="Logo" />
      <img v-else-if="hostname.includes('weseedo.nl') || hostname.includes('localhost')" src="../assets/logo-weseedo.svg" class="logo"  alt="WeSeeDo logo" />
    </div>
    <span class="d-block mt-2 font-size-rem-13" v-if="['home', 'login', 'auto_login', 'forgot_password', 'reset_password', 'activate_account'].includes($route.name) && (hostname.includes('weseedo.nl') || hostname.includes('localhost'))">
      <template v-if="$store.getters.getProduct === 'direct'">{{ $t('Login.Direct') }}</template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      hostname: ''
    }
  },
  methods: {
    closeNotificationBar (id) {
      this.$store.commit('addNotificationBarClosedId', id)
    }
  },
  components: {
    reconnecting: () => import('./Reconnecting')
  },
  computed: {
    reconnectingComponent () {
      return 'reconnecting'
    }
  },
  mounted () {
    this.hostname = window.location.hostname
  }
}
</script>

<style lang="scss" scoped>

</style>
