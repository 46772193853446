<template>
  <div class="container-fluid d-flex flex-column p-0" v-if="title && message">

    <weseedo-header></weseedo-header>

    <div class="container flex-grow-1 py-4">
      <div>
        <div class="row justify-content-center">
          <div class="d-flex flex-column col-12 col-lg-10 text-center">
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ title }}</h2>
            <p class="mb-1">{{ message }}</p>

            <div class="d-flex align-self-center align-items-center justify-content-center rounded-circle border border-primary w-px-100 h-px-100 mt-4">
              <font-awesome-icon :icon="['fas',  'wifi-slash']" class="font-size-px-40" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      title: 'Fout opgetreden',
      message: 'Er is een fout opgereden. Probeer het later opnieuw.'
    }
  },
  mounted () {
    // Just to be sure
    this.$webrtc.stopLocalStreams()
    if (this.$route.query.t) {
      this.title = this.$route.query.t || 'Foutmelding'
    }
    if (this.$route.query.m) {
      this.message = this.$route.query.m || 'Er is een fout opgereden. Probeer het later opnieuw.'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
