import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

let plugins = []
try {
  const vuexLocal = new VuexPersistence({
    supportCircular: true,
    storage: localStorage
  })
  plugins = [vuexLocal.plugin]
} catch (e) {
  console.log('Store init failed: ', e)
  plugins = []
}

export default new Vuex.Store({
  plugins: plugins,
  state: {
    button_expanded: true
  },
  mutations: {
    setButtonExpanded (state, expanded) {
      state.button_expanded = expanded
    }
  },
  getters: {
    getButtonExpanded: state => {
      return state.button_expanded
    }
  }
})
