<template>
        <div class="skeleton-loader" v-if="showLoader">
            <div class="row  mt-2 ml-1 mr-1  border-top border-left border-right border-bottom" v-for="skeleton in numberOfSkeletons" :key="skeleton">
                <div class="col-sm-3 text-center pt-2 pb-2 pt-sm-4 pb-sm-4">
                    <div class="skeleton-box" style="height: 20px; width: 80%;"></div>
                    <div class="skeleton-box" style="height: 10px; width: 60%; margin-top: 10px;"></div>
                    <div class="skeleton-box" style="height: 20px; width: 70%; margin-top: 10px;"></div>
                    <div class="mt-4 border-top d-none d-sm-block"></div>
                    <div class="skeleton-box" style="height: 20px; width: 50%; margin-top: 10px;"></div>
                    <div class="skeleton-box" style="height: 10px; width: 60%; margin-top: 10px;"></div>
                </div>
                <div class="col-sm-9 text-center pt-2 pb-2 pt-sm-4 pb-sm-4 bg-white w-100">
                    <div class="skeleton-box" style="height: 20px; width: 20%;"></div>
                    <div class="skeleton-box" style="height: 20px; width: 20%; margin-top: 10px;"></div>
                    <div class="skeleton-box mt-4" style="height: 20px; width: 60%; margin-top: 10px;"></div>
                    <div class="skeleton-box" style="height: 20px; width: 85%; margin-top: 10px;"></div>
                    <div class="skeleton-box" style="height: 20px; width: 85%; margin-top: 10px;"></div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
  props: {
    numberOfSkeletons: {
      type: Number,
      default: 5 // Default to 1 skeleton if no value is provided
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

.skeleton-box {
    background-color: #e0e0e0;
    margin-bottom: 10px;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #f0f0f0;
    }

    100% {
        background-color: #e0e0e0;
    }
}
</style>
