<template>
  <div class="container-fluid d-flex flex-column p-0">

    <weseedo-header></weseedo-header>

    <div class="container flex-grow-1 py-4" v-if="autoLoginFailed">
      <div class="row justify-content-center">
        <div class="d-flex flex-column col-12 col-sm-9 col-md-7 col-lg-5 text-center">
          <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('AutoLogin.Login failed') }}</h2>
          <p>{{ $t('AutoLogin.Token is invalid') }}</p>
        </div>
      </div>
    </div>

    <div class="container flex-grow-1 py-4" v-else>
      <div class="row justify-content-center">
        <div class="d-flex flex-column col-12 col-sm-9 col-md-7 col-lg-5 text-center">
          <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('AutoLogin.Login') }}</h2>
          <p>{{ $t('AutoLogin.One moment please') }}</p>
          <div class="d-flex align-self-center align-items-center justify-content-center rounded-circle border border-primary w-px-100 h-px-100 mt-4">
            <font-awesome-icon :icon="['fal', 'user-clock']" class="font-size-px-35" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AutoLogin',
  data () {
    return {
      autoLoginFailed: false
    }
  },
  methods: {
    async validateAutoLogin () {
      if (typeof this.$route.params.token === 'undefined' || this.$route.params.token === '') {
        this.autoLoginFailed = true
        return false
      }

      const auth = await this.$axios.autoLogin(this.$route.params.token)

      if (typeof auth.status !== 'undefined' && auth.status === 200) {
        this.$webrtc.connect()

        this.$root.getNotificationBar()

        if (typeof this.$route.query.redirect_after_login !== 'undefined' && ['waiting_room', 'appointment'].includes(this.$route.query.redirect_after_login)) {
          this.$router.push({ name: this.$route.query.redirect_after_login })
        } else {
          if (this.$store.getters.getProduct === 'direct') {
            this.$router.push({ name: 'appointment' })
          } else if (this.$store.getters.getProduct === 'personal') {
            if (this.$store.getters.getUser.role === 'assistent') {
              this.$router.push({ name: 'waiting_room' })
            } else {
              this.$router.push({ name: 'meeting' })
            }
          }
        }
      } else {
        this.autoLoginFailed = true
      }
    }
  },
  mounted () {
    this.$store.commit('setCamSettings', {})

    this.$root.getNotificationBar()

    this.$webrtc.resetIsInitialConnection()
    this.validateAutoLogin()
  }
}
</script>

<style lang="scss" scoped>

</style>
