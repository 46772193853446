import jQuery from './jquery'
import store from '../store'

window.LoggerAPI = (function () {
  var self = {
    queue: [],
    sendQueueTimeout: null,
    /**
     * Return the current log queue
     * @returns {Array} Log queue
     */
    getQueue: function () {
      return self.queue
    },
    /**
     * Clear the log queue
     * @returns {Boolean}
     */
    clearQueue: function () {
      self.queue = []

      return true
    },
    /**
     * Clear the log queue
     * @returns {Boolean}
     */
    resetQueue: function (failedQueue) {
      if (self.sendQueueTimeout !== null) {
        clearTimeout(self.sendQueueTimeout)
        self.sendQueueTimeout = null
      }

      self.queue = self.queue.concat(failedQueue)
      self.sendQueueTimeout = setTimeout(function () {
        self.sendLogs()
      }, 2500)
    },
    /**
     * Add log to queue
     * @returns {Boolean}
     */
    addLog: function (type, date, messageType, messageCode, message) {
      if (window.location.href.indexOf('conversation24') > -1) {
        return
      }
      if (self.sendQueueTimeout !== null) {
        clearTimeout(self.sendQueueTimeout)
        self.sendQueueTimeout = null
      }
      if (typeof type === 'undefined' || typeof date === 'undefined' || typeof messageType === 'undefined' || typeof messageCode === 'undefined' || typeof message === 'undefined') {
        return false
      }

      const userId = store.getters.getUser === false ? null : store.getters.getUser.id
      const participantId = store.getters.getMeeting === false ? null : store.getters.getUser === false ? store.getters.getMeeting.participant.id : store.getters.getMeeting.participants.filter(participant => participant.user === store.getters.getUser.id).map(participant => { return participant.id })
      const roomId = store.getters.getMeeting === false ? null : store.getters.getMeeting.room_id

      self.queue.push({
        'user': userId,
        'client': null,
        'participant': participantId,
        'room_id': roomId,
        'type': type,
        'date': date,
        'message_type': messageType,
        'message_code': messageCode,
        'message': message
      })

      if (self.sendQueueTimeout === null) {
        self.sendQueueTimeout = setTimeout(function () {
          self.sendLogs()
        }, 2500)
      }
      return true
    },

    /**
     * Send queue to Logger API. Use await to wait for promise result
     * @param {Boolean} remove_queue    Remove queue directly before waiting promise result. Use false to clear queue manually or true to do automatically. Note: using true will directly remove the log queue, also when logger API failed with errors.
     * @returns {Promise<any>} boolean true || error message
     */
    sendLogs: function () {
      self.sendQueueTimeout = null
      var queueToSend = self.getQueue()
      self.clearQueue()

      var postData = {
        'client_id': process.env.VUE_APP_LOGGER_API_CLIENT_ID,
        'client_secret': process.env.VUE_APP_LOGGER_API_CLIENT_SECRET
      }

      jQuery.ajax({
        'url': process.env.VUE_APP_LOGGER_API_HOST + '/auth/login',
        'method': 'POST',
        'data': JSON.stringify(postData),
        'crossDomain': true,
        'xhrFields': {
          'withCredentials': true
        },
        'dataType': 'json',
        'contentType': 'application/json'
      }).then(function (res1) {
        if (res1.success === true) {
          var accessToken = res1.result.access_token

          jQuery.ajax({
            'url': process.env.VUE_APP_LOGGER_API_HOST + '/logs',
            'method': 'POST',
            'data': JSON.stringify(queueToSend),
            'crossDomain': true,
            'xhrFields': {
              'withCredentials': true
            },
            'dataType': 'json',
            'contentType': 'application/json',
            'headers': {
              'Authorization': 'BearerToken ' + accessToken
            }
          }).then(function (res2) {
            if (res2.success === true) {
              console.log('LOG SUBMITTED')
            } else {
              console.log('LOG SEND FAILED, SUBMIT FAILED')
              self.resetQueue(queueToSend)
            }
          }, function (response) {
            console.log('LOG SEND FAILED, HTTP ERROR OCCURED')
            self.resetQueue(queueToSend)
          })
        } else {
          console.log('LOG SEND FAILED, AUTH FAILED')
          self.resetQueue(queueToSend)
        }
      }, function (response) {
        console.log('LOG SEND FAILED, HTTP ERROR OCCURED')
        self.resetQueue(queueToSend)
      })
    }
  }
  return self
}())
