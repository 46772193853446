<template>
  <!-- if unsupported browser -->
  <component :is="unsupportedBrowserComponent" v-if="unsupportedBrowser === true"></component>

  <!-- if meeting is loaded, apply cam settings -->
  <component :is="camSettingsComponent" v-else-if="meetingComponentLoaded === true && !skipCameraConfig && $store.getters.getCamSettings === false && $store.getters.getProduct === 'personal'"></component>

  <!-- if meeting is loaded -->
  <component :is="meetingComponent" v-else-if="meetingComponentLoaded === true && (skipCameraConfig || $store.getters.getCamSettings !== false || $store.getters.getProduct === 'direct')"></component>

  <!-- loader, wait till meeting is loaded -->
  <div class="container-fluid d-flex flex-column p-0" v-else-if="meetingNotFound === false && (!$root.isWebsocketAuthenticated || meetingComponentLoaded === false)">
    <div class="d-flex flex-grow-1 justify-content-center align-items-center">
      <font-awesome-icon :icon="['fal', 'spinner']" class="font-size-px-40" spin role="presentation" />
    </div>
  </div>

  <!-- meeting not found -->
  <div class="container-fluid d-flex flex-column p-0" v-else-if="meetingNotFound === true">
    <div class="d-flex flex-column w-100 h-100 overflow-auto">

      <weseedo-header></weseedo-header>

      <div class="container flex-grow-1 py-4">
        <div class="row justify-content-center">
          <div class="d-flex flex-column col-12 col-sm-9 col-md-8 text-center" v-if="tokenLogin === true">
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('Meeting.Link expired') }}</h2>
            <p>{{ $t('Meeting.The conversation is no longer available') }}</p>
          </div>
          <div class="d-flex flex-column col-12 col-sm-9 col-md-8 text-center" v-else>
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('Meeting.Meeting not found') }}</h2>
            <p>{{ $t('Meeting.No meeting was found') }}</p>
          </div>
        </div>

        <div class="row justify-content-center px-4" v-if="$store.getters.getUser !== false && $store.getters.isUINavigationEnabled">
          <div class="col-12 col-sm-9 col-md-7 col-lg-5 text-center mb-5 mt-5">
            <button type="button" class="btn btn-lg btn-primary mt-4" v-on:click="newSms">{{ $t('Meeting.Send new SMS') }}</button>
          </div>
        </div>
      </div>

    </div>

    <weseedo-footer v-if="$store.getters.getUser !== false && $store.getters.isUINavigationEnabled"></weseedo-footer>
  </div>
</template>

<script>
export default {
  name: 'Meeting',
  data () {
    return {
      meetingComponentLoaded: false,
      meetingNotFound: false,
      tokenLogin: false,
      meetingComponent: null,
      unsupportedBrowser: false,
      skipCameraConfig: false
    }
  },
  methods: {
    newSms () {
      this.$router.push({ name: 'appointment' })
    },
    onVisitorAlreadyActive () {
      this.$router.push({ name: 'meeting_participant_already_active' })
    }
  },
  components: {
    meetingRoom: () => import('./MeetingRoom'),
    unsupportedBrowser: () => import('./UnsupportedBrowser'),
    camSettings: () => import('./CamSettings')
  },
  computed: {
    unsupportedBrowserComponent () {
      return 'unsupportedBrowser'
    },
    camSettingsComponent () {
      return 'camSettings'
    }
  },
  destroyed () {
    this.$webrtc.EventBus.$off('onVisitorAlreadyActive', this.onVisitorAlreadyActive)
  },
  async mounted () {
    console.log('Mounted Meeting')

    const participantMeetingState = this.$store.getters.getParticipantMeetingState
    if (participantMeetingState) {
      if (participantMeetingState) {
        const currentUrl = window.location.href
        try {
          const { meetingUrl, requestBody } = await this.$webrtc.exchangeCodeForToken(currentUrl)
          if (meetingUrl) {
            const identificationId = this.$store.getters.getIdentificationId
            const meetingId = this.$store.getters.getMeetingId
            if (meetingId) {
              const saveParticipantName = await this.$axiosParticipant.post(this.$axiosParticipant.routes.meeting_personal + '/' + meetingId + '/save-participant-info', { requestBody, identificationId })
              if (typeof saveParticipantName.status !== 'undefined' && saveParticipantName.status === 200) {
                window.location.href = meetingUrl
              }
            }
          }
        } catch (err) {
          console.error(err)
        }
      }
    }

    // Meeting token login
    if (typeof this.$route.params.token !== 'undefined') {
      console.log('Token found in URL, try to login', this.$route.params.token)

      if (window.isWeSeeDoSupported === false) {
        this.unsupportedBrowser = true
        return false
      }

      this.tokenLogin = true

      let meetingLogin = await this.$axios.meetingLogin(this.$route.params.token)
      // Token login succeeded
      if (typeof meetingLogin.status !== 'undefined' && meetingLogin.status === 200) {
        let meetingObject = meetingLogin.data.result.meeting
        this.$store.commit('setCamSettings', false)
        this.$store.commit('setMeeting', meetingObject)
        if (meetingObject.product === 'weseedo' && meetingObject.is_appointment === false) {
          this.skipCameraConfig = true
        }
        console.log('Meeting token found for meeting:', meetingLogin.data)

        if (this.$store.getters.getUser !== false) {
          this.$webrtc.connect()
        }

        this.meetingComponent = 'meetingRoom'
        this.meetingComponentLoaded = true
      // Token login failed, we got status PARTICIPANT_ALREADY_ACTIVE, show onVisitorAlreadyActive page
      } else if (typeof meetingLogin.status !== 'undefined' && meetingLogin.status === 401 && typeof meetingLogin.data !== 'undefined' && typeof meetingLogin.data.error_code !== 'undefined' && meetingLogin.data.error_code.includes('PARTICIPANT_ALREADY_ACTIVE')) {
        console.log('Participant is already active, MeetingRoom not loaded')
        this.onVisitorAlreadyActive()
      // Login not possible, show disconnect again
      }

      if (typeof meetingLogin.status === 'undefined') {
        this.$root.isAppReconnecting = false
        this.$root.isAppDisconnected = true
      // Token login failed, show meeting not found
      } else {
        console.log('Meeting is not found, MeetingRoom not loaded')
        this.meetingNotFound = true
      }
    // No token login, forward to login page when no user is found in store
    } else if (this.$store.getters.getUser === false) {
      console.log('No meeting token found and no user found, forward to login')
      if (this.$store.getters.getCurrentUrl) {
        window.location.href = this.$store.getters.getCurrentUrl
      }
      this.$router.push({ name: 'login' })
    // Login is found in store. In case of 'direct' there always have to be a meeting before MeetingRoom load. For personal you can access MeetingRoom directly without meeting
    } else if (this.$store.getters.getMeeting === false && this.$store.getters.getProduct === 'direct') {
      console.log('Product is direct and meeting is not found, MeetingRoom not loaded')
      this.meetingNotFound = true
    // No token login, login is found and meeting already exists in store in case of direct.
    } else {
      console.log('No token found, login is found')

      this.meetingComponent = 'meetingRoom'
      this.meetingComponentLoaded = true
    }

    this.$webrtc.EventBus.$on('onVisitorAlreadyActive', this.onVisitorAlreadyActive)
  }
}
</script>

<style lang="scss" scoped>

</style>
