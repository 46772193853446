<template>
  <div>
    <div class="d-flex bg-white w-100 h-px-65 align-items-center border-top font-size-rem-11 footer disable-select" v-if="typeof $route.query.disable_footer === 'undefined' || $route.query.disable_footer === 'false'">
      <!-- logo -->
      <div class="ml-2 ml-sm-3" v-if="typeof $route.query.disable_logo === 'undefined' || $route.query.disable_logo === 'false'">
        <img v-if="$store.getters.getMeeting !== false && typeof $store.getters.getMeeting.site !== 'undefined' && typeof $store.getters.getMeeting.site.logo !== 'undefined' && $store.getters.getMeeting.site.logo !== null" :src="$store.getters.getMeeting.site.logo" class="logo_footer" alt="Site logo" />
        <img v-else-if="hostname.includes('weseedo') || hostname.includes('localhost')" src="../assets/logo-weseedo.svg" class="logo_footer" alt="WeSeeDo logo" />
      </div>
      <!-- status bar -->
      <div class="ml-2 ml-sm-3 text-truncate" v-if="$route.name === 'meeting' && $store.getters.getMeetingStarted !== false && (typeof $route.query.disable_statusbar_text === 'undefined' || $route.query.disable_statusbar_text === 'false')" :style="{color: $store.getters.getMeeting.site.videochat_text_color }">
        {{ $t('FooterParticipant.In conversation with {participants}', {'participants': getRemoteMeetingParticipants}) }}
      </div>
      <div class="d-flex ml-auto">
        <!-- meeting counter -->
        <div class="d-flex ml-auto mr-2 mr-sm-3" v-if="$route.name === 'meeting' && (typeof $route.query.disable_timer === 'undefined' || $route.query.disable_timer === 'false')">
          {{ getMeetingTime }}
        </div>
        <!-- div class="d-flex ml-1 font-size-px-25" v-if="this.$store.getters.getMeetingStarted !== false">
          <router-link :to="''"  @click.native="openDeviceSettingsDialog">
            <font-awesome-icon :icon="['fas', 'gear']" class="cursor-pointer mr-2 mr-sm-3" />
          </router-link>
        </div -->
        <tooltip-wrapper allowSpace="true" v-if="this.$store.getters.getMeetingStarted !== false" tabindex="0" @emitFunction="openDeviceSettingsDialog" :tooltipText="$t('Accessibility/Tooltip.Settings')">
          <div class="d-flex mr-2 mr-sm-3 font-size-px-25 align-items-center">
            <font-awesome-icon :icon="['fas', 'gear']" v-on:click="openDeviceSettingsDialog" class="cursor-pointer" />
          </div>
        </tooltip-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipWrapper from './TooltipWrapper.vue'

export default {
  name: 'FooterParticipant',
  data () {
    return {
      meetingCounter: 0,
      meetingCounterInterval: null,
      hostname: ''
    }
  },
  methods: {
    openDeviceSettingsDialog () {
      // this.$router.push({ name: 'device_settings' })
      this.$webrtc.toggleDeviceSettingsPane()
    },
    setMeetingCounter () {
      if (this.$store.getters.getMeetingStarted === true) {
        this.meetingCounterInterval = setInterval(() => {
          this.meetingCounter++
        }, 1000)
      } else {
        clearInterval(this.meetingCounterInterval)
        this.meetingCounterInterval = null
        this.meetingCounter = 0
      }
    }
  },
  components: {
    TooltipWrapper
  },
  computed: {
    getRemoteMeetingParticipants () {
      let participants = this.$store.getters.getRemoteMeetingParticipants

      if (participants === false) {
        return
      }

      participants = Object.values(participants).map((participant) => {
        if (participant === 'Bezoeker') {
          return this.$t('Footer.Visitor')
        } else {
          return participant
        }
      })

      if (participants.length === 1) {
        return participants[0]
      } else {
        const lastParticipant = participants.pop()
        return participants.join().replace(/,/g, ', ') + ' ' + this.$t('MeetingRoom.and') + ' ' + lastParticipant
      }
    },
    getMeetingTime () {
      let time = new Date(this.meetingCounter * 1000).toISOString().substr(11, 8)

      // hours are 0, remove starting zero and double point
      if (Number(time.substr(0, 2)) === 0) {
        time = time.substr(3)
        // hours lower than 10, remove starting zero
      } else if (Number(time.substr(0, 2)) < 10) {
        time = time.substr(1)
      }

      return time
    },
    meetingStarted () {
      return this.$store.getters.getMeetingStarted
    }
  },
  watch: {
    meetingStarted () {
      // just in case mounted is later, it will still watch the store meetingStarted change
      this.setMeetingCounter()
    },
    $route (to, from) {
    }
  },
  destroyed () {
    clearInterval(this.meetingCounterInterval)
  },
  mounted () {
    this.setMeetingCounter()
    this.hostname = window.location.hostname
  }
}
</script>

<style lang="scss" scoped>
</style>
