<template>
  <div class="container-fluid d-flex flex-column p-0">

    <weseedo-header></weseedo-header>

    <div class="container py-4" v-if="passwordRequestPosted === false">
      <div class="row justify-content-center">
        <div class="col-10 col-sm-8 col-md-6 col-lg-5">
          <h2 class="font-weight-normal text-center border-bottom pb-2 mb-5">{{ $t('ForgotPassword.Forgot password') }}</h2>
          <form method="post" @submit.prevent="requestPassword">
            <div class="form-group">
              <label for="email" class="mb-0 font-weight-bold">{{ $t('ForgotPassword.Email address') }}</label>
              <input spellcheck="false" type="email" class="form-control" id="email" name="email" ref="email" v-model="email">
            </div>
            <div class="d-flex">
              <button type="submit" class="btn btn-lg btn-primary btn-block" :class="{ disabled: passwordRequestButtonDisabled }" :disabled="passwordRequestButtonDisabled">{{ $t('ForgotPassword.New password') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="container py-4" v-else>
      <div class="row justify-content-center">
        <div class="d-flex flex-column col-10 col-md-8 col-lg-6 text-center">
          <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('ForgotPassword.Email sent') }}</h2>
          <p>{{ $t('ForgotPassword.An email has been sent, check your mail') }}</p>
          <p><strong>{{ $t('ForgotPassword.Note:') }}</strong> {{ $t('ForgotPassword.We will only send this email if the email address you provided is known to us') }}</p>
        </div>
      </div>

      <div class="row justify-content-center px-4">
        <div class="col-12 col-sm-9 col-md-7 col-lg-5 text-center mt-4">
          <button type="button" class="btn btn-lg btn-primary mt-4" v-on:click="$router.push({ name: 'login' })">{{ $t('ForgotPassword.Login') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forgot',
  data () {
    return {
      email: '',
      passwordRequestButtonDisabled: true,
      passwordRequestPosted: false
    }
  },
  methods: {
    requestPassword () {
      this.passwordRequestPosted = true

      let postData = {
        'product': process.env.VUE_APP_PRODUCT,
        'email': this.email
      }

      this.$axios.post(this.$axios.routes.forgot_password, postData)
    }
  },
  watch: {
    email () {
      this.passwordRequestButtonDisabled = !(this.email !== '')
    }
  },
  mounted () {
    this.$refs.email.focus()
    this.$axios.logout(false)
  }
}
</script>

<style lang="scss" scoped>

</style>
