<template>
  <div class="container-fluid d-flex flex-column p-0">

    <div class="d-flex flex-column align-items-center mb-3 flex-grow-1 px-2 min-h-px-80 max-vh-20" :class="[loading === false ? 'd-flex' : 'd-none', { 'container': !isFramed } ]">
      <component v-if="false" class="ml-auto mt-2 w-px-150" :is="languageSwitcherComponent"></component>
    </div>

    <div id="website-overview" class="flex-column flex-grow-1 px-2 text-black align-items-center p-2" v-if="this.sites.length !== 0" :class="[loading === false ? 'd-flex' : 'd-none', { 'container': !isFramed } ]">
      <h1 class="mb-4">{{ $t('Iframe/WebsiteFormOverview.Make a choice please') }}</h1>
      <div class="row w-100 max-w-px-700">
        <div class="m-0 p-0" :class="[ sites.length === 11 ? 'col-12' : 'col-12 col-md-6' ]" v-for="site in this.sites" :key="site.id" @click="$router.push({ name: $i18n.locale === 'nl' ? 'form_plannen' : 'form_schedule', params: { site: site.id } })">
          <a class="d-flex cursor-pointer p-3 m-3 bg-white btn btn-secondary border border-input-border">
            {{ site.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'iframe-resizer/js/iframeResizer.contentWindow.min'

export default {
  name: 'WebsiteForm',
  data () {
    return {
      loading: true,
      sites: [],
      isFramed: null
    }
  },
  methods: {
    async getSites () {
      this.loading = true

      let query = {}

      if (this.$route.query.id) {
        query.id = this.$route.query.id
      } else {
        query.domain = window.location.hostname
      }

      query = new URLSearchParams(query).toString()

      let sites = await this.$axiosSite.get(this.$axiosSite.routes.site_overview + '?' + query)

      if (sites.status !== null && sites.status === 200 && typeof sites.data.result !== 'undefined') {
        this.sites = sites.data.result

        this.loading = false
      }
    }
  },
  components: {
    languageSwitcher: () => import('../library/LanguageSwitcher')
  },
  computed: {
    languageSwitcherComponent () {
      return 'languageSwitcher'
    }
  },
  watch: {
  },
  async mounted () {
    try {
      this.isFramed = window.self !== window.top
    } catch (e) {
      this.isFramed = true
    }

    let sheet = window.document.styleSheets[0]
    if (!this.isFramed) {
      sheet.insertRule('html.iframe-form, html.iframe-form body { height: 100% !important; }', sheet.cssRules.length)
    }

    await this.getSites()
  }
}
</script>

<style lang="scss" scoped></style>
