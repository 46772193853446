<template>
  <div class="d-flex justify-content-center w-px-320" v-if="detectRTC !== null && detectRTC.hasWebcam && detectRTC.hasMicrophone && isWeSeeDoSupported && (busyAgents.length > 0 || onlineAgents.length > 0)">
    <div class="d-flex flex-column align-items-center bg-white w-px-300 position-relative text-button shadow-blur rounded-top" :class="[ state === 'expanded' ? selectedAgent !== null && site.overlay_show_agent && selectedAgent.photo ? 'h-px-230 margin-top-40' : 'h-px-190 margin-top-10' : 'h-px-65 margin-top-10' ]">
      <div class="position-absolute position-left-0 position-top-0 cursor-pointer h-px-40 w-px-40 text-gray" @click="toggleState">
        <font-awesome-icon class="font-size-px-40 margin-left-10" :icon="state === 'expanded' ? ['fal', 'angle-down'] : ['fal', 'angle-up']" />
      </div>

      <div class="margin-min-top-40 h-px-75 w-px-75 rounded-circle overflow-hidden" v-if="state === 'expanded' && selectedAgent !== null && site.overlay_show_agent && selectedAgent.photo">
        <img :src="selectedAgent.photo" alt="Agent" />
      </div>

      <h1 class="margin-top-30" :style="{ 'color': typeof site.overlay_text_color !== 'undefined' && site.overlay_text_color !== null ? site.overlay_text_color : '' }" v-if="state === 'expanded' && selectedAgent !== null">{{ site.overlay_title_online }}</h1>
      <h1 class="margin-top-30" :style="{ 'color': typeof site.overlay_text_color !== 'undefined' && site.overlay_text_color !== null ? site.overlay_text_color : '' }" v-else-if="state === 'expanded' && selectedAgent === null">{{ site.overlay_title_offline }}</h1>

      <form target="_blank" method="post" @submit.prevent="submitForm">
        <button class="btn font-weight-normal text-white margin-top-10 w-px-200 h-px-45" :class="{'bg-button': typeof site.overlay_text_color === 'undefined' || site.overlay_text_color === null}" :style="{ 'backgroundColor': typeof site.overlay_text_color !== 'undefined' && site.overlay_text_color !== null ? site.overlay_text_color : '' }" type="submit" v-if="selectedAgent">
          <font-awesome-icon :icon="['fas', 'video']" class="margin-right-10" /> {{ site.overlay_button_text_online }}
        </button>
        <button class="btn font-weight-normal bg-gray margin-top-10 w-px-200 h-px-45" :class="{'text-white': typeof site.overlay_text_color === 'undefined' || site.overlay_text_color === null}" :style="{ 'color': typeof site.overlay_text_color !== 'undefined' && site.overlay_text_color !== null ? site.overlay_text_color : '' }" type="button" disabled v-else>
          {{ site.overlay_button_text_offline }}
        </button>
      </form>

      <div class="margin-top-25 font-size-px-14" :style="{ 'color': typeof site.overlay_text_color !== 'undefined' && site.overlay_text_color !== null ? site.overlay_text_color : '' }" v-if="state === 'expanded' && selectedAgent !== null">{{ site.overlay_availability_text_online }}</div>
      <div class="margin-top-25 font-size-px-14" :style="{ 'color': typeof site.overlay_text_color !== 'undefined' && site.overlay_text_color !== null ? site.overlay_text_color : '' }" v-else-if="state === 'expanded' && selectedAgent === null">{{ site.overlay_availability_text_offline }}</div>
    </div>
  </div>
</template>

<script>
import 'iframe-resizer/js/iframeResizer.contentWindow.min'

export default {
  name: 'WebsiteButton',
  data () {
    return {
      apiBaseUrl: process.env.VUE_APP_API_HOST,
      token: null,
      site: null,
      selectedAgent: null,
      detectRTC: null,
      isWeSeeDoSupported: null,
      state: this.$localStore.getters.getButtonExpanded ? 'expanded' : 'collapsed',
      windowReference: null
    }
  },
  methods: {
    async submitForm () {
      let formInfo = await this.$axiosSite.post(this.$axiosSite.routes.button + (this.$root.isBeta === true ? '?beta=true' : ''), {
        a: this.selectedAgent.id,
        s: this.site.id,
        t: this.token
      })
      this.$utils.postMessageToParent('MEETING_CREATED', { meeting_id: formInfo.data.meeting_id, agent_id: this.selectedAgent.id })
      window.open(formInfo.data.url, '_blank')
    },
    toggleState () {
      this.state = this.state === 'expanded' ? 'collapsed' : 'expanded'
      this.$localStore.commit('setButtonExpanded', this.state === 'expanded')
    },
    async refreshUsers () {
      setTimeout(async () => {
        let siteInfo = await this.$axiosSite.get(this.$axiosSite.routes.site + '/' + this.$route.query.s + '?return_images=true')
        if (siteInfo !== null) {
          this.site = siteInfo.data.result
        }
      }, 1000)
    },
    onLostConnection () {
      this.site = null
    },
    onDisconnected () {
      this.site = null
    },
    onReconnected () {
      this.refreshUsers()
    }
  },
  watch: {
    onlineAgents () {
      let agentFound = false
      if (this.selectedAgent !== null) {
        let selectedAgentStillOnline = this.onlineAgents.filter(user => user.id === this.selectedAgent.id)
        if (selectedAgentStillOnline !== null && selectedAgentStillOnline.length > 0) {
          this.selectedAgent = selectedAgentStillOnline[0]
          agentFound = true
        }
      }

      if (!agentFound) {
        let agent = this.onlineAgents[Math.floor(Math.random() * this.onlineAgents.length)]

        if (typeof agent === 'undefined') {
          this.selectedAgent = null
        } else {
          this.selectedAgent = agent
        }
      }
    }
  },
  computed: {
    onlineAgents () {
      if (this.site === null || typeof this.site.users === 'undefined' || this.site.users.length === 0) {
        return []
      }

      return this.site.users.filter(user => (user.role === 'agent' || user.role === 'assistent_video') && user.status === 'online')
    },
    busyAgents () {
      if (this.site === null || typeof this.site.users === 'undefined' || this.site.users.length === 0) {
        return []
      }
      let busyAgents = this.site.users.filter(user => (user.role === 'agent' || user.role === 'assistent_video') && (user.status === 'busy' || user.status === 'request_pending'))
      return busyAgents
    }
  },
  destroyed () {
    this.$webrtc.EventBus.$off('onAgentStatusChanged', this.refreshUsers)
    this.$webrtc.EventBus.$off('onLostConnection', this.onLostConnection)
    this.$webrtc.EventBus.$off('onDisconnected', this.onDisconnected)
    this.$webrtc.EventBus.$off('onReconnected', this.onReconnected)
  },
  async mounted () {
    // Disable existing disconnect handlers, since we need to just hide the button whenever we disconnect here
    this.$webrtc.EventBus.$off('onDisconnected')
    this.$webrtc.EventBus.$on('onAgentStatusChanged', this.refreshUsers)
    this.$webrtc.EventBus.$on('onLostConnection', this.onLostConnection)
    this.$webrtc.EventBus.$on('onDisconnected', this.onDisconnected)
    this.$webrtc.EventBus.$on('onReconnected', this.onReconnected)

    window.DetectRTC.load(() => {
      this.detectRTC = window.DetectRTC
    })

    this.isWeSeeDoSupported = window.isWeSeeDoSupported

    let authInfo = await this.$axiosSite.post(this.$axiosSite.routes.login_site, {
      site_id: this.$route.query.s
    })
    if (authInfo && typeof authInfo.data !== 'undefined' && typeof authInfo.data.result !== 'undefined') {
      let token = authInfo.data.result.access_token
      this.token = token
      this.$store.commit('setSiteAccessToken', token)
      this.$axiosSite.setToken(token)
      this.refreshUsers()
      this.$webrtc.connect()
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  margin-top: -2px;
  margin-left: -2px;
}
</style>
