<template>
  <div class="input-group color-picker" ref="colorpicker" @mousedown="$event.stopPropagation()">
    <sketch :value="colors" :disable-alpha="true" @input="updateFromPicker" v-if="displayPicker && !compact" :preset-colors="presetColors" />
    <compact :value="colors" :disable-alpha="true" @input="updateFromPicker" v-if="displayPicker && compact" :palette="palette" />
  </div>
</template>

<script>
import { Sketch, Compact } from 'vue-color'

export default {
  props: {
    color: String,
    compact: Boolean
  },
  data () {
    return {
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      displayPicker: false,
      presetColors: ['#073c58', '#31e86d', '#ed4054', '#F2C037', '#F8E71C', '#0FE1B6', '#000000', '#FFFFFF'],
      palette: ['#ffff00', '#00ff00', '#0000ff', '#ff0000', '#ffffff']
    }
  },
  emits: ['onColorPickerOpen', 'onColorPickerClosed'],
  methods: {
    setColor (color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors (color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        }
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        const hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)

        this.colors = {
          hex: hex,
          a: rgba[3]
        }
      }
    },
    showPicker () {
      this.displayPicker = true
      this.$emit('onColorPickerOpen')
      document.addEventListener('mousedown', this.documentClick)
    },
    hidePicker () {
      this.displayPicker = false
      this.$emit('onColorPickerClosed')
      document.removeEventListener('mousedown', this.documentClick)
    },
    togglePicker () {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },
    async updateFromPicker (color) {
      this.colors = color

      if (color.rgba.a === 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
      }
    },
    documentClick () {
      this.hidePicker()
    }
  },
  components: {
    Sketch,
    Compact
  },
  watch: {
    colorValue (val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val) // can be used as v-model
      }
    }
  },
  mounted () {
    this.setColor(this.color || '#000000')
  }
}
</script>

<style lang="scss" scoped>
</style>
