<template>
  <div class="container-fluid d-flex flex-column p-0">

    <weseedo-header></weseedo-header>

    <div class="container flex-grow-1 py-4">
      <div class="row justify-content-center">
        <div class="d-flex flex-column col-12 col-sm-9 col-md-7 col-lg-5 text-center">
          <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('ForcedLogout.Disconnected') }}</h2>
          <p class="mb-1">{{ $t('ForcedLogout.Signed in on another device') }}</p>

          <div class="d-flex align-self-center align-items-center justify-content-center rounded-circle border border-primary w-px-100 h-px-100 mt-4">
            <font-awesome-icon :icon="['fas',  'wifi-slash']" class="font-size-px-40" />
          </div>
        </div>
      </div>
      <div class="row justify-content-center px-4" v-if="$store.getters.isUINavigationEnabled">
        <div class="col-12 col-sm-9 col-md-7 col-lg-5 text-center mb-5 mt-5">
          <router-link tag="button" :to="{'name': 'login'}" class="btn btn-lg btn-primary mt-4 px-sm-5">{{ $t('ForcedLogout.Log in again') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForcedLogout',
  data () {
    return {
    }
  },
  mounted () {
    this.$webrtc.stopLocalStreams()
    this.$axios.logout(false)
    this.$webrtc.disconnect(false)
    this.$noSleep.disable()
  }
}
</script>

<style lang="scss" scoped>

</style>
