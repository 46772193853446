import Vue from 'vue'
import VueI18n from 'vue-i18n'

import nl from '../languages/nl-NL.json'
import en from '../languages/en-US.json'
import de from '../languages/de-DE.json'
import fr from '../languages/fr-FR.json'
import es from '../languages/es-ES.json'

Vue.use(VueI18n)

const messages = {
  'nl': nl,
  'en': en,
  'de': de,
  'fr': fr,
  'es': es
}

export default new VueI18n({ locale: 'nl', fallbackLocale: 'en', messages })
