import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import i18n from './utils/i18n'
import jQuery from './utils/jquery'

Vue.use(Vuex)

let plugins = []
try {
  const vuexLocal = new VuexPersistence({
    supportCircular: true,
    storage: sessionStorage
  })
  plugins = [vuexLocal.plugin]
} catch (e) {
  console.log('Store init failed: ', e)
  plugins = []
}

export default new Vuex.Store({
  plugins: plugins,
  state: {
    access_token: null,
    participant_access_token: null,
    site_access_token: null,
    user: {},
    enable_ui_navigation: true,
    meeting: {},
    identification_started: false,
    identification_triggered: false,
    meeting_started: false,
    meeting_disconnected_as: null,
    meeting_disconnected: false,
    meeting_ended_as: null,
    identification_ended: false,
    meeting_ended: false,
    meeting_remote_participants: {},
    last_meeting_info: {},
    is_meeting_host: false,
    feedback_forms: [],
    forced_logout: false,
    product: false,
    status: null,
    notification_bar: {},
    notification_bar_closed_ids: [],
    devices: {},
    cam_settings: {},
    alwaysAvailableInterval: null,
    code_verifier: null,
    participant_meeting_state: null,
    identification_id: null,
    meeting_id: null,
    current_url: null
  },
  mutations: {
    setCurrentUrl (state, currentUrl) {
      state.current_url = currentUrl
    },
    setMeetingId (state, meetingId) {
      state.meeting_id = meetingId
    },
    setCodeVerifier (state, codeVerifier) {
      state.code_verifier = codeVerifier
    },
    setParticipantMeetingState (state, participantMeetingState) {
      state.participant_meeting_state = participantMeetingState
    },
    setIdentificationId (state, identificationId) {
      state.identification_id = identificationId
    },
    setAccessToken (state, accessToken) {
      state.access_token = accessToken
    },
    setParticipantAccessToken (state, accessToken) {
      state.participant_access_token = accessToken
    },
    setSiteAccessToken (state, accessToken) {
      state.site_access_token = accessToken
    },
    setUser (state, user) {
      state.user = user
    },
    enableUINavigation (state, enable) {
      state.enable_ui_navigation = enable
    },
    setMeeting (state, meeting) {
      state.meeting = meeting

      if (Object.keys(state.meeting).length > 0) {
        if (typeof state.meeting.site !== 'undefined' && typeof state.meeting.site.feedback_agent !== 'undefined') {
          if (state.feedback_forms.filter(form => form.id === state.meeting.site.feedback_agent.id).length === 0) {
            state.feedback_forms.push(state.meeting.site.feedback_agent)
          }
        }
        if (typeof state.meeting.site !== 'undefined' && typeof state.meeting.site.feedback_participant !== 'undefined') {
          if (state.feedback_forms.filter(form => form.id === state.meeting.site.feedback_participant.id).length === 0) {
            state.feedback_forms.push(state.meeting.site.feedback_participant)
          }
        }
      } else {
        state.meeting_remote_participants = {}
        state.is_meeting_host = false
      }
    },
    setMeetingStarted (state, started) {
      state.meeting_started = started
    },
    setIdentificationStarted (state, started) {
      state.identification_started = started
    },
    setIdentificationTriggered (state, triggered) {
      state.identification_triggered = triggered
    },
    setMeetingDisconnectedAs (state, disconnectedAs) {
      state.meeting_disconnected_as = disconnectedAs
    },
    setMeetingDisconnected (state, disconnected) {
      state.meeting_disconnected = disconnected
    },
    setMeetingEndedAs (state, endedAs) {
      state.meeting_ended_as = endedAs
    },
    setMeetingEnded (state, ended) {
      state.meeting_ended = ended
    },
    setIdentificationEnded (state, ended) {
      state.identification_ended = ended
    },
    setIsMeetingHost (state, isMeetingHost) {
      state.is_meeting_host = isMeetingHost
    },
    setForcedLogout (state, forced) {
      state.forced_logout = forced
    },
    setLocale (state) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const queryParams = Object.fromEntries(urlSearchParams.entries())
      if (queryParams.lang && i18n.availableLocales.includes(queryParams.lang)) {
        i18n.locale = queryParams.lang
      } else if (this.getters.getUser !== false && typeof this.getters.getUser.locale !== 'undefined') {
        const userLang = this.getters.getUser.locale
        const foundLocale = i18n.availableLocales.find(availableLocale => {
          return userLang.includes(availableLocale)
        })
        if (foundLocale !== false) {
          i18n.locale = foundLocale
        } else {
          const browserLang = navigator.language.split('-')[0]
          const foundBrowserLocale = i18n.availableLocales.find(availableLocale => {
            return browserLang.includes(availableLocale)
          })
          if (foundBrowserLocale !== false) {
            i18n.locale = foundBrowserLocale
          }
        }
      } else if (this.getters.getMeeting !== false && typeof this.getters.getMeeting.locale !== 'undefined') {
        const meetingLang = this.getters.getMeeting.locale
        const foundLocale = i18n.availableLocales.find(availableLocale => {
          return meetingLang.includes(availableLocale)
        })
        if (foundLocale !== false) {
          i18n.locale = foundLocale
        } else {
          const browserLang = navigator.language.split('-')[0]
          const foundBrowserLocale = i18n.availableLocales.find(availableLocale => {
            return browserLang.includes(availableLocale)
          })
          if (foundBrowserLocale !== false) {
            i18n.locale = foundBrowserLocale
          }
        }
      } else {
        const browserLang = navigator.language.split('-')[0]
        const foundBrowserLocale = i18n.availableLocales.find(availableLocale => {
          return browserLang.includes(availableLocale)
        })
        if (foundBrowserLocale !== false) {
          i18n.locale = foundBrowserLocale
        }
      }

      jQuery('html').attr('lang', i18n.locale)
    },
    setProduct (state, product) {
      state.product = product
    },
    setStatus (state, status) {
      state.status = status
    },
    setNotificationBar (state, notification) {
      state.notification_bar = notification
    },
    addNotificationBarClosedId (state, id) {
      state.notification_bar_closed_ids.push(id)
    },
    addRemoteMeetingParticipant (state, event) {
      Vue.set(state.meeting_remote_participants, event.video.id, event.real_name)
    },
    removeRemoteMeetingParticipant (state, streamId) {
      Vue.delete(state.meeting_remote_participants, streamId)
    },
    removeFeedbackForms (state) {
      state.feedback_forms = []
    },
    removeFeedbackFormById (state, id) {
      state.feedback_forms = state.feedback_forms.filter((form) => {
        return form.id !== id
      })
    },
    setLastMeetingInfo (state, info) {
      state.last_meeting_info = info
    },
    setDeviceList (state, devices) {
      state.devices = devices
    },
    setCamSettings (state, settings) {
      state.cam_settings = settings
    },
    setAlwaysAvailableInterval (state, intervalId) {
      state.alwaysAvailableInterval = intervalId
    }
  },
  getters: {
    getCurrentUrl (state) {
      return state.current_url
    },
    getMeetingId (state) {
      return state.meeting_id
    },
    getCodeVerifier: state => {
      return state.code_verifier
    },
    getParticipantMeetingState: state => {
      return state.participant_meeting_state
    },
    getIdentificationId (state) {
      return state.identification_id
    },
    getAlwaysAvailableInterval: state => {
      return state.alwaysAvailableInterval
    },
    getAccessToken: state => {
      return state.access_token
    },
    getParticipantAccessToken: state => {
      return state.participant_access_token
    },
    getSiteAccessToken: state => {
      return state.site_access_token
    },
    getUserSites: state => {
      return state.user.sites
    },
    getSiteTree: state => {
      let sitesCopy = JSON.parse(JSON.stringify(state.user.sites))
      let treeOrderedSites = sitesCopy.filter(site => !site.parent)
      for (const site of treeOrderedSites) {
        site.depth = 0
      }
      let sitesWithParent = sitesCopy.filter(site => site.parent)
      let attempts = 0
      do {
        // Just to be sure we don't infinite loop here
        attempts++
        for (const siteIndex in sitesWithParent) {
          let site = sitesWithParent[siteIndex]
          let shouldBreak = false
          for (let treeSiteIndex in treeOrderedSites) {
            let treeSite = treeOrderedSites[treeSiteIndex]
            if (treeSite.id === site.parent) {
              site.depth = treeSite.depth + 1
              treeOrderedSites.splice(parseInt(treeSiteIndex) + 1, 0, site)
              sitesWithParent.splice(siteIndex, 1)
              shouldBreak = true
              break
            }
          }
          if (shouldBreak) {
            break
          }
        }
      } while (sitesCopy.length > treeOrderedSites.length && attempts <= 100)

      let sheet = window.document.styleSheets[0]

      let i = 1
      for (const site of treeOrderedSites) {
        i++ // start with nth-child 2 as the first option is "make a choice'
        sheet.insertRule('#select2-site-results .select2-results__option:nth-child(' + i + ') { padding-left: ' + (site.depth * 20 + 5) + 'px; }', sheet.cssRules.length)
      }

      return treeOrderedSites.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    },
    getUser: state => {
      if (state.user !== null && Object.keys(state.user).length > 0) {
        return state.user
      } else {
        return false
      }
    },
    getUserAccount: state => {
      if (Object.keys(state.user).length > 0 && typeof state.user.sites !== 'undefined' && typeof state.user.sites[0] !== 'undefined') {
        return state.user.sites[0]
      } else {
        return false
      }
    },
    getUserAccountById: state => (id) => {
      if (Object.keys(state.user.sites).length > 0) {
        return state.user.sites.find(x => x.id === id)
      } else {
        return false
      }
    },
    isUINavigationEnabled: state => {
      return state.enable_ui_navigation
    },
    agentHasCallColleague: (state) => () => {
      return Object.keys(state.user).length > 0 && state.user.call_colleague === true
    },
    userHasFeature: (state) => (feature) => {
      return Object.keys(state.user).length > 0 && typeof state.user.entity !== 'undefined' && typeof state.user.entity.package !== 'undefined' && typeof state.user.entity.package.features !== 'undefined' && state.user.entity.package.features.includes(feature)
    },
    participantHasFeature: (state) => (feature) => {
      return Object.keys(state.meeting).length > 0 && typeof state.meeting.features !== 'undefined' && state.meeting.features[feature]
    },
    siteHasFeature: (state) => (feature) => {
      return Object.keys(state.meeting).length > 0 && typeof state.meeting.site !== 'undefined' && typeof state.meeting.site.enabled_features !== 'undefined' && state.meeting.site.enabled_features.includes(feature)
    },
    dealerHasFeature: (state) => (feature) => {
      return Object.keys(state.meeting).length > 0 && typeof state.meeting.company?.dealer !== 'undefined' && typeof state.meeting.company?.dealer?.features !== 'undefined' && state.meeting.company?.dealer?.features.includes(feature)
    },
    getMeeting: state => {
      if (Object.keys(state.meeting).length > 0) {
        return state.meeting
      } else {
        return false
      }
    },
    getMeetingStarted: state => {
      return state.meeting_started
    },
    getIdentificationStarted: state => {
      return state.identification_started
    },
    getIdentificationTriggered: state => {
      return state.identification_triggered
    },
    getMeetingDisconnectedAs: (state) => {
      if (state.meeting_disconnected_as === 'agent') {
        return 'agent'
      } else if (state.meeting_disconnected_as === 'participant') {
        return 'participant'
      } else {
        return null
      }
    },
    getMeetingDisconnected: (state) => {
      return state.meeting_disconnected
    },
    getMeetingEndedAs: (state) => {
      if (state.meeting_ended_as === 'agent') {
        return 'agent'
      } else if (state.meeting_ended_as === 'participant') {
        return 'participant'
      } else {
        return null
      }
    },
    getMeetingEnded: (state) => {
      return state.meeting_ended
    },
    getIdentificationEnded: (state) => {
      return state.identification_ended
    },
    getIsMeetingHost: (state) => {
      return state.is_meeting_host
    },
    getForcedLogout: (state) => {
      return state.forced_logout
    },
    getProduct: (state) => {
      if (state.product === 'personal' || state.product === 'direct') {
        return state.product
      } else {
        return null
      }
    },
    getStatus: (state) => {
      return state.status
    },
    getNotificationBar: (state) => {
      return state.notification_bar
    },
    getNotificationBarClosedIds: (state) => {
      return state.notification_bar_closed_ids
    },
    getRemoteMeetingParticipants: (state) => {
      if (Object.keys(state.meeting_remote_participants).length > 0) {
        return state.meeting_remote_participants
      } else {
        return false
      }
    },
    getFeedbackForms: (state) => {
      return state.feedback_forms
    },
    getLastMeetingInfo: (state) => {
      if (Object.keys(state.last_meeting_info).length > 0) {
        return state.last_meeting_info
      } else {
        return false
      }
    },
    getDeviceList: (state) => {
      return state.devices
    },
    getCamSettings: (state) => {
      if (Object.keys(state.cam_settings).length > 0) {
        return state.cam_settings
      } else {
        return false
      }
    }
  }
})
