<template>
  <div class="container-fluid d-flex flex-column p-0">
    <!-- step 1 -->
    <div class="container h-100" v-if="step === 1">
      <div class="row justify-content-center h-100">
        <div class="d-flex flex-column col-11 col-sm-10 col-lg-7 text-center align-items-center my-4 align-self-center">
          <div class="d-flex align-items-center justify-content-center rounded-circle border border-primary w-px-140 h-px-140 mb-4">
            <font-awesome-icon :icon="['fal', 'clipboard-list-check']" class="font-size-px-65" />
          </div>

          <h2 class="font-weight-normal border-bottom pb-2 mb-5 px-2">{{ $t('HardwareTest.Welcome to the hardware test') }}</h2>

          <p>
            {{ $t('HardwareTest.Welcome to the test to check whether you have a suitable camera and that your software is configured correctly to work with your hardware') }}
          </p>

          <button @click="step = 2" class="btn btn-lg btn-primary mt-3 mb-3">{{ $t('HardwareTest.Start the test')}}</button>

          <p>
            <em>{{ $t('HardwareTest.We do not record anything during the test') }}</em>
          </p>
        </div>
      </div>
    </div>

    <!-- step 2/3 -->
    <div class="container d-flex flex-column h-100" v-else-if="step === 2 || step === 3">
      <div class="d-flex justify-content-center text-center pt-3 ">
        <div class="w-px-150 pb-2 underlined" :class="{ 'active': step === 2 }">
          {{ $t('HardwareTest.Camera') }}
        </div>
        <div class="w-px-150 pb-2 underlined" :class="{ 'active': step === 3 }">
          {{ $t('HardwareTest.Microphone') }}
        </div>
      </div>

      <div class="d-flex flex-column flex-grow-1 text-center align-items-center">
        <font-awesome-icon :icon="['fal', 'webcam']" v-if="step === 2" class="font-size-px-50 mt-5 mb-4" />
        <font-awesome-icon :icon="['fal', 'microphone']" v-if="step === 3" class="font-size-px-50 mt-5 mb-4" />

        <div class="row justify-content-center" v-if="(step === 2 && (isCameraBlocked || isCameraNotReadable)) || (step === 3 && (isMicrophoneBlocked || isMicrophoneNotReadable))">
          <div class="d-flex flex-column col text-center align-items-center align-self-center">
            <p v-if="step === 2 && isCameraBlocked" v-html="$t('HardwareTest.Camera declined')"></p>
            <p v-if="step === 3 && isMicrophoneBlocked" v-html="$t('HardwareTest.Microphone declined')"></p>

            <p v-if="step === 2 && isCameraNotReadable" v-html="$t('HardwareTest.Camera not readable')"></p>
            <p v-if="step === 3 && isMicrophoneNotReadable" v-html="$t('HardwareTest.Microphone not readable')"></p>
          </div>
        </div>

        <div v-if="step === 2 && isCameraNotFound">
          <p v-html="$t('HardwareTest.Camera not found')"></p>
          <div v-html="$t('HardwareTest.No video help')"></div>
        </div>
        <div v-if="step === 3 && isMicrophoneNotFound">
          <p v-html="$t('HardwareTest.Microphone not found')"></p>
          <div v-html="$t('HardwareTest.No audio help')"></div>
        </div>

        <h1 v-if="step === 2 && !isCameraBlocked && !isCameraNotReadable && !isCameraNotFound">
          {{ $t('HardwareTest.Do you see yourself?')}}
        </h1>
        <h1 v-if="step === 3 && !isMicrophoneBlocked && !isMicrophoneNotReadable && !isMicrophoneNotFound">
          {{ $t('HardwareTest.Do you hear yourself?')}}
        </h1>

        <div class="row justify-content-center mt-2" v-if="step === 3 && !isMicrophoneBlocked && !isMicrophoneNotReadable && !isMicrophoneNotFound">
          <div class="d-flex flex-column 11 col-sm-10 col-lg-9 text-center align-items-center align-self-center">
            <p v-html="$t('HardwareTest.For example say Welcome to the video call')"></p>
          </div>
        </div>

        <div id="local" class="d-flex max-w-px-320 mt-4 p-0">

        </div>
      </div>

      <div class="d-flex text-center justify-content-center pt-4 pb-3">
        <button @click="step += 1" :disabled="!allowNext" class="btn btn-lg btn-primary mr-4 w-px-150">{{ $t('HardwareTest.Yes')}}</button>
        <button @click="openDialog(step)" class="btn btn-lg btn-primary w-px-150">{{ $t('HardwareTest.No')}}</button>
      </div>
    </div>

    <!-- step 4 -->
    <div class="container h-100" v-else-if="step === 4">
      <div class="row justify-content-center h-100">
        <div class="col-11 col-sm-10 col-lg-7 text-center">
          <font-awesome-icon :icon="['fal', 'sparkles']" class="font-size-px-50 mt-5" />

          <h1 class="mt-5 mb-4">{{ $t('HardwareTest.Everything works!')}}</h1>
          <p v-html="$t('HardwareTest.Test successfull')"></p>
          <img class="img-fluid shadow-blur w-px-400 mt-4" src="@/assets/hardwaretest-done.png" alt="Hardwaretest" />
        </div>
      </div>

    </div>

    <!-- Modal video not working -->
    <div id="modal-no-video" class="modal modal-no-video" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body d-flex flex-column pb-3">
            <h1 class="mb-3 align-self-center">{{ $t('HardwareTest.No video') }}</h1>

            <div v-html="$t('HardwareTest.No video help')"></div>

            <button @click="performTest(step)" class="btn btn-lg btn-primary px-5 mt-2 align-self-center">{{ $t('HardwareTest.Test again')}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal audio not working -->
    <div id="modal-no-audio" class="modal modal-no-audio" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body d-flex flex-column pb-3">
            <h1 class="mb-3 align-self-center">{{ $t('HardwareTest.No audio') }}</h1>

            <div v-html="$t('HardwareTest.No audio help')"></div>

            <button @click="performTest(step)" class="btn btn-lg btn-primary px-5 mt-2 align-self-center">{{ $t('HardwareTest.Test again')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HardwareTest',
  data () {
    return {
      detectRTC: null,
      step: 1,
      allowNext: false,
      isCameraBlocked: false,
      isMicrophoneBlocked: false,
      isCameraNotReadable: false,
      isMicrophoneNotReadable: false,
      isCameraNotFound: false,
      isMicrophoneNotFound: false
    }
  },
  methods: {
    closeDialogs () {
      this.$('#modal-no-video').modal('hide')
      this.$('#modal-no-audio').modal('hide')
    },
    openDialog (step) {
      this.closeDialogs()
      if (step === 2) {
        this.$('#modal-no-video').modal({ backdrop: 'static', keyboard: false }, 'show')
      } else if (step === 3) {
        this.$('#modal-no-audio').modal({ backdrop: 'static', keyboard: false }, 'show')
      }
    },
    performTest (step) {
      this.closeDialogs()
      this.allowNext = false
      this.$webrtc.stopLocalStreams()

      this.$('#local').html('')
      // eslint-disable-next-line
      let localStream = Erizo.Stream({
        video: step === 2 || step === 3 ? {
          width: { ideal: 640 },
          height: { ideal: 360 }
        } : false,
        audio: step === 3,
        data: true,
        local: true
      })
      localStream.addEventListener('access-accepted', () => {
        if (this.step === 2) {
          this.isCameraBlocked = false
          this.isCameraNotFound = false
          this.isCameraNotReadable = false
        } else if (this.step === 3) {
          this.isMicrophoneBlocked = false
          this.isMicrophoneNotFound = false
          this.isMicrophoneNotReadable = false
        }

        this.allowNext = true
        let stream = localStream
        let video = document.createElement('video')
        video.setAttribute('autoplay', 'autoplay')
        video.setAttribute('playsinline', 'playsinline')
        video.srcObject = stream.stream
        video.style.maxWidth = '100%'
        video.classList.add('webrtc-hardwaretest-video')

        this.$('#local').html(video)

        this.$('#local').find('video').one('loadeddata', () => {
          this.$('#local').addClass('webrtc-small-video-hardwaretest')
        })
      })
      localStream.addEventListener('access-denied', (event) => {
        if (this.step === 2) {
          if (event.msg.name === 'NotReadableError') {
            this.isCameraNotReadable = true
          } else if (event.msg.name === 'NotFoundError') {
            this.isCameraNotFound = true
          } else {
            this.isCameraBlocked = true
          }
        } else if (this.step === 3) {
          if (event.msg.name === 'NotReadableError') {
            this.isMicrophoneNotReadable = true
          } else if (event.msg.name === 'NotFoundError') {
            this.isMicrophoneNotFound = true
          } else {
            this.isMicrophoneBlocked = true
          }
        }
      })
      localStream.init()
    }
  },
  watch: {
    step: function (newStep) {
      if (newStep === 2 || newStep === 3) {
        this.performTest(newStep)
      }
    }
  },
  mounted () {
    window.DetectRTC.load(() => {
      this.detectRTC = window.DetectRTC
    })
  }
}
</script>

<style lang="scss" scoped>
  .underlined {
    border-bottom: 1px solid #DAE2E6;
  }
  .underlined.active {
    border-bottom: 1px solid #073C58;
  }
</style>
