<template>
  <div id="modal-call-agent" class="modal modal-call-agent" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center pt-5 pb-3 font-size-rem-13">
          <h1 class="mb-5">{{ $t('Modals/CallAgent.Incoming call') }}</h1>

          <p v-if="type === 'call' && meeting.is_appointment === false">
            {{ $t('Modals/CallAgent.A visitor wants to start a video call') }}
          </p>
          <p v-if="type === 'call' && meeting.is_appointment === true && meeting.participants.filter(participant => participant.status === 'online').length === 1">
            {{ $t('Modals/CallAgent.{PARTICIPANT} is present for the scheduled video call starting on {START_TIME}', { 'PARTICIPANT': getParticipantsList, 'START_TIME': moment(this.meeting.room_available_from).format($t('Formats.Time')) }) }}
          </p>
          <p v-if="type === 'call' && meeting.is_appointment === true && meeting.participants.filter(participant => participant.status === 'online').length > 1">
            {{ $t('Modals/CallAgent.{PARTICIPANTS} are present for the scheduled video call starting on {START_TIME}', { 'PARTICIPANTS': getParticipantsList, 'START_TIME': moment(this.meeting.room_available_from).format($t('Formats.Time')) }) }}
          </p>
          <p v-else-if="type === 'invite'">
            {{ $t('Modals/CallAgent.{NAME} would like to add you to the video call starting on {START_TIME} with {PARTICIPANTS}', { 'NAME': requestedBy, 'START_TIME': moment(this.meeting.room_available_from).format($t('Formats.Time')), 'PARTICIPANTS': getParticipantsList }) }}
          </p>
          <p v-else-if="type === 'forward'">
            {{ $t('Modals/CallAgent.{NAME} would like to hand over the video call starting on {START_TIME} with {PARTICIPANTS}', { 'NAME': requestedBy, 'START_TIME': moment(this.meeting.room_available_from).format($t('Formats.Time')), 'PARTICIPANTS': getParticipantsList }) }}
          </p>
          <p v-else-if="type === 'colleague'">
            {{ $t('Modals/CallAgent.{NAME} would like to call you', {'NAME': requestedBy} ) }}
          </p>

          <span v-if="meeting && meeting.site">{{ $t('Modals/CallAgent.Site: {SITE}', { 'SITE': meeting.site.name }) }}</span>

          <div class="row justify-content-center mt-5">
            <div class="col-12 col-sm-4 col-md-3 col-xl-2 mb-4">
              <button @click="callAccept" type="button" class="btn btn-lg btn-green text-white w-px-150 h-px-65">{{ $t('Modals/CallAgent.Accept') }}</button>
            </div>
            <div class="col-12 col-sm-4 col-md-3 col-xl-2 mb-4">
              <button @click="callHold" type="button" :disabled="callHeld" class="btn btn-lg btn-warning text-white w-px-150 h-px-65">
                <font-awesome-icon v-if="callHeld" :icon="['fas', 'pause']" class="mr-2" /> {{ $t('Modals/CallAgent.Wait') }}
              </button>
            </div>
            <div class="col-12 col-sm-4 col-md-3 col-xl-2 mb-4" v-if="typeof $route.params.token === 'undefined'">
              <button @click="callDecline" type="button" class="btn btn-lg btn-danger text-white w-px-150 h-px-65">{{ $t('Modals/CallAgent.Decline') }}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallAgent',
  data () {
    return {
      type: null,
      requestedBy: null,
      roomId: null,
      site: null,
      meeting: null,
      callHeld: false,
      ringtone: null,
      dialogOpen: false
    }
  },
  methods: {
    stopRinger () {
      this.ringtone.pause()
      this.ringtone.currentTime = 0
    },
    async callAccept () {
      console.log('Call accepted')

      this.closeCallAgent()
      this.$store.commit('setMeeting', this.meeting)

      if (this.type === 'colleague') {
        this.$webrtc.callColleagueAccept(this.roomId)

        await this.$router.push({ name: 'meeting' })
      } else {
        if (this.$route.name !== 'meeting') {
          this.$root.webrtcJoin = {
            room_id: this.roomId,
            product: this.meeting.product,
            stream2way: this.meeting.stream_camera_two_way
          }

          await this.$router.push({ name: 'meeting' })
        } else {
          this.$webrtc.join(this.roomId, this.meeting.product, this.meeting.stream_camera_two_way, true)
        }
      }
    },
    callHold () {
      console.log('Call on hold')

      this.callHeld = true
      // TODO JAAP: Figure out if we really need accountId here at all
      this.$webrtc.hold(this.roomId)

      this.stopRinger()
    },
    callDecline () {
      console.log('Call declined')

      this.$webrtc.decline(this.roomId)
      this.$webrtc.setStatus('offline', true)

      this.closeCallAgent()
    },
    async callAgent (action, requestedBy, roomId, account) {
      console.log('Incoming call:', action, requestedBy, roomId, account)

      let betaQuery = ''
      if (this.$root.isBeta) {
        betaQuery = '&beta=true'
      }

      const meetings = await this.$axios.get(this.$axios.routes.meeting_personal + '?room_id=' + roomId + betaQuery)

      if (typeof meetings.data.result === 'undefined' || meetings.data.result === null) {
        return false
      }

      this.meeting = meetings.data.result[0]
      this.type = action

      if (this.type === 'invite' || this.type === 'forward' || this.type === 'colleague') {
        this.requestedBy = requestedBy
      }

      this.roomId = roomId
      this.site = account

      this.dialogOpen = true

      if (typeof this.$route.query.auto_accept !== 'undefined' && this.$route.query.auto_accept === 'true') {
        this.callAccept()
      } else {
        this.$('#modal-call-agent').modal({ backdrop: 'static', keyboard: false }, 'show')
        if (typeof this.$route.query.disable_ringtone_incoming_call === 'undefined' || this.$route.query.disable_ringtone_incoming_call === 'false') {
          await this.ringtone.play()
        }
      }

      this.callHeld = false
    },
    async visitorStatusChanged (status, username) {
      if (this.type === 'invite' || this.type === 'forward' || this.type === 'colleague' || this.dialogOpen === false) {
        return false
      }

      console.log('Visitor status changed, change in participants array for call dialog')

      let participants

      if (status === 'online') {
        participants = this.meeting.participants.map(participant => {
          if (participant.username === username) {
            participant.status = 'online'
          }
          return participant
        })
      } else {
        participants = this.meeting.participants.map(participant => {
          if (participant.username === username) {
            participant.status = 'offline'
          }
          return participant
        })
      }

      this.$set(this.meeting, 'participants', participants)

      if (this.meeting.participants.filter(participant => participant.status === 'online').length === 0) {
        this.closeCallAgent()

        let newStatus

        if (this.$store.getters.isUINavigationEnabled) {
          if (this.$store.getters.getStatus === 'online') {
            newStatus = 'online'
          } else {
            newStatus = 'offline'
          }
        } else {
          newStatus = 'online_for_single_use_meeting'
        }

        this.$webrtc.setStatus(newStatus, true, newStatus !== 'online_for_single_use_meeting', newStatus !== 'online_for_single_use_meeting')
      }
    },
    onVisitorOnline (username) {
      this.visitorStatusChanged('online', username)
    },
    onVisitorOffline (username) {
      this.visitorStatusChanged('offline', username)
    },
    onCancelOffer () {
      this.closeCallAgent()
    },
    onStreamNoLongerAvailable (event) {
      console.log('Stream no longer available', event)

      if (this.dialogOpen === true) {
        this.callDecline()
      }
    },
    closeCallAgent () {
      this.stopRinger()
      this.dialogOpen = false
      this.$('#modal-call-agent').modal('hide')
    }
  },
  computed: {
    getParticipantsList () {
      if (this.meeting === null) {
        return ''
      }

      let participants = this.meeting.participants

      if (typeof participants === 'undefined' || participants.length === 0) {
        return ''
      }

      participants = participants.filter(participant => participant.status === 'online').map((participant) => {
        if (participant.name === 'Bezoeker') {
          return this.$t('Modals/CallAgent.Visitor')
        } else {
          return participant.name
        }
      })

      // [0] is never the agent here, because in dialog callAgent is the status 'request_peding' for agents after onRequestJoin
      if (participants.length === 1) {
        return participants[0]
      } else {
        const lastParticipant = participants.pop()
        return participants.join().replace(/,/g, ', ') + ' ' + this.$t('Modals/CallAgent.and') + ' ' + lastParticipant
      }
    }
  },
  destroyed () {
    console.log('Destroyed CallAgent dialog')

    this.$eventBus.$off('callAgent', this.callAgent)

    this.$webrtc.EventBus.$off('onVisitorOnline', this.onVisitorOnline)
    this.$webrtc.EventBus.$off('onVisitorOffline', this.onVisitorOffline)
    this.$webrtc.EventBus.$off('onCancelOffer', this.onCancelOffer)
    this.$webrtc.EventBus.$off('onStreamNoLongerAvailable', this.onStreamNoLongerAvailable)

    this.stopRinger()
  },
  mounted () {
    console.log('Mounted CallAgent dialog')

    this.ringtone = new Audio('/sounds/ringtone.mp3')
    this.ringtone.loop = true

    this.$eventBus.$on('callAgent', this.callAgent)

    this.$webrtc.EventBus.$on('onVisitorOnline', this.onVisitorOnline)
    this.$webrtc.EventBus.$on('onVisitorOffline', this.onVisitorOffline)
    this.$webrtc.EventBus.$on('onCancelOffer', this.onCancelOffer)
    this.$webrtc.EventBus.$on('onStreamNoLongerAvailable', this.onStreamNoLongerAvailable)
  }
}
</script>

<style lang="scss" scoped>
</style>
