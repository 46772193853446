
import store from '../../src/store'
const CLIENT_ID = process.env.VUE_APP_SIGNICAT_CLIENT_ID
// const REDIRECT_URI = 'http://localhost:8085'
const TEST_REDIRECT_URI = 'https://test.local' // bacause signicat only allows https url. More info in README.md
const SIGNICAT_URL = process.env.VUE_APP_SIGNICAT_URL
const SIGNICAT_OIDC_URL = `${SIGNICAT_URL}/broker/sp/oidc`
const AUTHORIZATION_URL = `${SIGNICAT_OIDC_URL}/authenticate`

// Function to generate a random string for code_verifier
function generateCodeVerifier () {
  const array = new Uint8Array(32)
  crypto.getRandomValues(array)
  return base64UrlEncode(array)
}

// Function to create code_challenge from code_verifier
async function generateCodeChallenge (codeVerifier) {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const digest = await crypto.subtle.digest('SHA-256', data)
  return base64UrlEncode(new Uint8Array(digest))
}

// Helper function to base64url encode an array
function base64UrlEncode (array) {
  return btoa(String.fromCharCode.apply(null, array))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

async function generatePKCEPair () {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = await generateCodeChallenge(codeVerifier)

  return { codeVerifier, codeChallenge }
}

function generateRandomString (length) {
  const array = new Uint8Array(length)
  window.crypto.getRandomValues(array)
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    ''
  )
}

export async function redirectToAuthorizationEndpoint (currentUrl) {
  if (!currentUrl) {
    return
  }
  const url = new URL(currentUrl)
  const hostUrl = url.origin
  const urlPath = url.pathname

  const { codeVerifier, codeChallenge } = await generatePKCEPair()

  // Store the code verifier in store
  store.commit('setCodeVerifier', codeVerifier)

  const clientId = CLIENT_ID
  const redirectUri = process.env.NODE_ENV === 'development-personal' ? `${TEST_REDIRECT_URI}/meeting` : encodeURIComponent(`${hostUrl}/meeting`)

  const responseType = 'code'
  const scope = encodeURIComponent('openid profile nin idp_scoping:digid idp_scoping:https://was-preprod1.digid.nl/saml/idp/metadata')
  const state = `${generateRandomString(16)}==${urlPath}` // Random string to protect against CSRF
  const codeChallengeMethod = 'S256'

  store.commit('setParticipantMeetingState', state)

  const authorizationUrl =
    `${AUTHORIZATION_URL}?` +
    `client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}` +
    `&state=${state}&scope=${scope}&code_challenge=${codeChallenge}` +
    `&code_challenge_method=${codeChallengeMethod}&prompt=login`

  // Redirect to the authorization server
  window.location.href = authorizationUrl
}

export async function exchangeCodeForToken (currentUrl) {
  if (!currentUrl) {
    return
  }
  const url = new URL(currentUrl)
  const hostUrl = url.origin

  const urlParams = new URLSearchParams(url.search)
  const authorizationCode = urlParams.get('code')
  const state = urlParams.get('state')
  const storedState = store.getters.getParticipantMeetingState

  // Validate the state
  if (state !== storedState) {
    console.error('State validation failed')
    return
  }

  const meetingToken = state.split('==')[1]

  const codeVerifier = store.getters.getCodeVerifier
  const redirectUri = process.env.NODE_ENV === 'development-personal' ? `${TEST_REDIRECT_URI}` : hostUrl

  const body = {
    grant_type: 'authorization_code',
    redirect_uri: `${redirectUri}/meeting`,
    code: authorizationCode,
    code_verifier: codeVerifier
  }

  // Use the access token to access protected resources
  const meetingUrl = `${redirectUri}${meetingToken}`
  return { meetingUrl, requestBody: body }
}
