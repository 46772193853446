<template>
  <!-- if unsupported browser -->
  <component :is="unsupportedBrowserComponent" v-if="unsupportedBrowser === true"></component>

  <!-- meeting cancelled -->
  <div class="container-fluid d-flex flex-column p-0" v-else-if="meetingComponentLoaded === true && meetingCancelled === true">
    <div class="d-flex flex-column w-100 h-100 overflow-auto">

      <weseedo-header></weseedo-header>

      <div class="container flex-grow-1 py-4">
        <div class="row justify-content-center">
          <div class="d-flex flex-column col-12 col-sm-9 col-md-8 text-center">
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('MeetingCancel.Appointment cancelled') }}</h2>
            <p>{{ $t('MeetingCancel.Your appointment is cancelled') }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- cancel meeting form -->
  <div class="container-fluid d-flex flex-column p-0" v-else-if="meetingComponentLoaded === true && meetingCancelled === false">
    <div class="d-flex flex-column w-100 h-100 overflow-auto">

      <weseedo-header></weseedo-header>

      <div class="container flex-grow-1 py-4">
        <div class="row justify-content-center">
          <div class="d-flex flex-column col-12 col-sm-9 col-md-8 text-center">
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('MeetingCancel.Cancel appointment') }}</h2>
            <p>{{ $t('MeetingCancel.Why do you want to cancel') }}</p>
            <form method="post" @submit.prevent="postCancel">
              <div class="form-group">
                <textarea class="form-control h-px-150" id="message" name="message" ref="message" v-model="message"></textarea>
              </div>
              <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-lg btn-primary" :class="{ disabled: cancelLoading }" :disabled="cancelLoading" v-if="cancelLoading === false">{{ $t('MeetingCancel.Cancel appointment') }}</button>
                <button type="submit" class="btn btn-lg btn-primary disabled" disabled="disabled" v-else><font-awesome-icon :icon="['fal', 'spinner']" spin role="presentation" /> {{ $t('MeetingCancel.Loading') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- not able to cancel appointments as agent -->
  <div class="container-fluid d-flex flex-column p-0" v-else-if="meetingComponentLoaded === false && tokenIsFromAgent === true">
    <div class="d-flex flex-column w-100 h-100 overflow-auto">

      <weseedo-header></weseedo-header>

      <div class="container flex-grow-1 py-4">
        <div class="row justify-content-center">
          <div class="d-flex flex-column col-12 col-sm-9 col-md-8 text-center">
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('MeetingCancel.Link not available') }}</h2>
            <p>{{ $t('MeetingCancel.Agents can not cancel the appointment') }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- loader, wait till login is loaded -->
  <div class="container-fluid d-flex flex-column p-0" v-else-if="meetingComponentLoaded === false && meetingNotFound === false && meetingAlreadyStarted === false">
    <div class="d-flex flex-grow-1 justify-content-center align-items-center">
      <font-awesome-icon :icon="['fal', 'spinner']" class="font-size-px-40" spin role="presentation" />
    </div>
  </div>

  <!-- meeting not found -->
  <div class="container-fluid d-flex flex-column p-0" v-else-if="meetingNotFound === true">
    <div class="d-flex flex-column w-100 h-100 overflow-auto">

      <weseedo-header></weseedo-header>

      <div class="container flex-grow-1 py-4">
        <div class="row justify-content-center">
          <div class="d-flex flex-column col-12 col-sm-9 col-md-8 text-center">
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('MeetingCancel.Link expired') }}</h2>
            <p>{{ $t('MeetingCancel.The conversation is no longer available') }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- meeting already started -->
  <div class="container-fluid d-flex flex-column p-0" v-else-if="meetingAlreadyStarted === true">
    <div class="d-flex flex-column w-100 h-100 overflow-auto">

      <weseedo-header></weseedo-header>

      <div class="container flex-grow-1 py-4">
        <div class="row justify-content-center">
          <div class="d-flex flex-column col-12 col-sm-9 col-md-8 text-center">
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('MeetingCancel.Meeting already started') }}</h2>
            <p>{{ $t('MeetingCancel.The meeting has already started') }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetingCancel',
  data () {
    return {
      meetingComponentLoaded: false,
      meetingNotFound: false,
      meetingAlreadyStarted: false,
      unsupportedBrowser: false,
      tokenLogin: false,
      message: '',
      cancelLoading: false,
      meetingCancelled: false,
      tokenIsFromAgent: false,
      checkCancelLinkInterval: null,
      meeting: null
    }
  },
  methods: {
    async postCancel () {
      const formData = {}
      formData.message = this.message

      const cancelMeeting = await this.$axiosParticipant.post(this.$axiosParticipant.routes.meeting_personal + '/' + this.$store.getters.getMeeting.id + '/cancel', formData)

      if (typeof cancelMeeting.status !== 'undefined' && cancelMeeting.status === 200) {
        clearInterval(this.checkCancelLinkInterval)
        this.meetingCancelled = true
      } else {
        this.meetingAlreadyStarted = true
      }
      this.meetingComponentLoaded = true
    },
    onVisitorAlreadyActive () {
      this.$router.push({ name: 'meeting_participant_already_active' })
    },
    async isCancelMeetingLinkStillValid () {
      console.log(this.meeting, this.moment(this.meeting.room_available_from).utc(), this.moment().utc(), this.moment(this.meeting.room_available_from).utc() <= this.moment().utc())
      if (this.meeting && this.moment(this.meeting.room_available_from).utc() <= this.moment().utc()) {
        this.meetingNotFound = true
        this.meetingComponentLoaded = false
        clearInterval(this.checkCancelLinkInterval)
      }
    }
  },
  components: {
    unsupportedBrowser: () => import('./UnsupportedBrowser')
  },
  computed: {
    unsupportedBrowserComponent () {
      return 'unsupportedBrowser'
    }
  },
  destroyed () {
    this.$webrtc.EventBus.$off('onVisitorAlreadyActive', this.onVisitorAlreadyActive)
  },
  async mounted () {
    console.log('Mounted MeetingCancel')
    // Meeting cancel token login
    if (typeof this.$route.params.token !== 'undefined') {
      console.log('Token found in URL, try to login', this.$route.params.token)

      if (window.isWeSeeDoSupported === false) {
        this.unsupportedBrowser = true
        return false
      }

      this.tokenLogin = true

      let meetingLogin = await this.$axios.meetingLogin(this.$route.params.token)
      // Token login succeeded
      if (typeof meetingLogin.status !== 'undefined' && meetingLogin.status === 200) {
        this.meeting = meetingLogin.data.result.meeting
        console.log('Meeting token found for meeting:', meetingLogin.data)
        let meetingStartDate = this.moment(meetingLogin.data.result.meeting.room_available_from)
        if (meetingStartDate.utc() <= this.moment().utc() || !meetingLogin.data.result.meeting.participant.is_first_session) {
          this.meetingNotFound = true
        } else {
          if (meetingLogin.data.result.request_type === 'agent') {
            this.$axios.logout()
            this.tokenIsFromAgent = true
          } else {
            this.checkCancelLinkInterval = setInterval(this.isCancelMeetingLinkStillValid, 1000)
            // NOTE: comment the next line out when the form must NOT be shown
            this.meetingComponentLoaded = true

            // NOTE: UNcomment the next line when the form must NOT be shown (directly POST cancel to API)
            // this.postCancel()
          }
        }
      // Token login failed, we got status PARTICIPANT_ALREADY_ACTIVE, show onVisitorAlreadyActive page
      } else if (typeof meetingLogin.status !== 'undefined' && meetingLogin.status === 401 && typeof meetingLogin.data !== 'undefined' && typeof meetingLogin.data.error_code !== 'undefined' && meetingLogin.data.error_code.includes('PARTICIPANT_ALREADY_ACTIVE')) {
        console.log('Participant is already active, MeetingRoom not loaded')
        this.onVisitorAlreadyActive()
      // Login not possible, show disconnect again
      } else if (typeof meetingLogin.status === 'undefined') {
        this.$root.isAppReconnecting = false
        this.$root.isAppDisconnected = true
      // Token login failed, show meeting not found
      } else {
        console.log('Meeting is not found, MeetingRoom not loaded')
        this.meetingNotFound = true
      }
    }

    this.$webrtc.EventBus.$on('onVisitorAlreadyActive', this.onVisitorAlreadyActive)
  }
}
</script>

<style lang="scss" scoped>

</style>
