<template>
  <component :is="appointmentComponent"></component>
</template>

<script>
export default {
  name: 'Appointment',
  data () {
    return {
      appointmentComponent: null
    }
  },
  components: {
    directAppointmentComponent: () => import('./direct/Appointment'),
    personalAppointmentComponent: () => import('./personal/Appointment')
  },
  mounted () {
    if (this.$store.getters.getProduct === 'direct') {
      this.appointmentComponent = 'directAppointmentComponent'
    } else if (this.$store.getters.getProduct === 'personal') {
      this.appointmentComponent = 'personalAppointmentComponent'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
