const Utils = class Utils {
  postMessageToParent (type, data) {
    parent.postMessage({
      'type': 'WESEEDO_' + type,
      'data': data
    }, '*')
  }
}

export { Utils }
