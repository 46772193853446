<template>
  <div class="container-fluid d-flex flex-column p-0">

    <weseedo-header></weseedo-header>

    <div class="container flex-grow-1 py-4">
      <div v-if="typeof $route.params.meeting_id === 'undefined'">
        <div class="row justify-content-center">
          <div class="d-flex flex-column col-12 col-lg-10 text-center">
            <h2 class="font-weight-normal border-bottom pb-2 mb-4">{{ $t('UnsupportedBrowser.Browser not supported') }}</h2>
            <p class="mb-1">{{ $t('UnsupportedBrowser.Your browser is not supported') }}</p>
            <div v-if="downloadSafari" v-html="$t('UnsupportedBrowser.Use Safari to continue')"></div>
            <div v-else-if="downloadSafariChromeFirefox" v-html="$t('UnsupportedBrowser.Use Safari or download Chrome to continue')"></div>
            <div v-else-if="downloadEdgeChromeFirefox" v-html="$t('UnsupportedBrowser.Download Edge or Chrome to continue')"></div>
            <div v-else-if="downloadChromeFirefox" v-html="$t('UnsupportedBrowser.Download Edge or Chrome to continue')"></div>

            <template v-if="$route.params.token">
              <p class="mt-1">
                {{ $t('UnsupportedBrowser.Copy the url into another browser') }}
              </p>

              <div class="d-flex justify-content-center">
                <input ref="meetingUrl" type="text" readonly="readonly" v-model="location" class="form-control max-w-px-500">
                <div class="d-flex align-items-center justify-content-center h-px-50 w-px-70 cursor-pointer bg-lightgray border border-input-border rounded ml-1" @click="copyMeetingUrl">
                  <font-awesome-icon :icon="['fal', 'copy']" class="font-size-px-20" />
                </div>
              </div>
            </template>

            <div class="d-flex align-self-center align-items-center justify-content-center rounded-circle border border-primary w-px-100 h-px-100 mt-4">
              <font-awesome-icon :icon="['fas',  'wifi-slash']" class="font-size-px-40" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      os: null,
      browser: null,
      location: window.location,
      downloadSafari: false,
      downloadSafariChromeFirefox: false,
      downloadEdgeChromeFirefox: false,
      downloadChromeFirefox: false
    }
  },
  methods: {
    copyMeetingUrl () {
      let copyText = this.$refs['meetingUrl']

      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      document.execCommand('copy')

      console.log('Copied URL to clipboard: ' + copyText.value)
    }
  },
  mounted () {
    // Just to be sure
    this.$webrtc.stopLocalStreams()

    window.DetectRTC.load(() => {
      this.os = window.DetectRTC.osName
      this.browser = window.DetectRTC.browser.name

      if (this.os.includes('iOS')) {
        this.downloadSafari = true
      } else if (this.os.includes('Mac OS')) {
        this.downloadSafariChromeFirefox = true
      } else if (this.os.includes('Windows')) {
        this.downloadEdgeChromeFirefox = true
      } else {
        this.downloadChromeFirefox = true
      }
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
